import React, { useContext, useEffect } from "react";
import "./index.css";
import Hand from "./Hand/Hand";
import FastContext from "../../context/FastContext";
import { isIOS } from "react-device-detect";
const MacKeyboard = (props) => {
  const ctxfast = useContext(FastContext);
  useEffect(() => {
    ctxfast.changeLangOrLevel("MON", 50);
  }, []);
  return (
    <div id="keyboard">
      <ul className="cf" id="numbers">
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("192"))) ? (
            <a
              href="#"
              className="key c192"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("192")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("192") && "red",
              }}
            >
              {ctxfast.state.keyboardlang == "MON" ? (
                <span>
                  <b>+</b>
                  <span>=</span>
                </span>
              ) : (
                <span>
                  <b>~</b>
                  <span>`</span>
                </span>
              )}
            </a>
          ) : (
            <a className="key c192"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("49"))) ? (
            <a
              href="#"
              className="key c49"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("49")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("49") && "red",
              }}
            >
              {ctxfast.state.keyboardlang == "MON" ? (
                <span>
                  <b>1</b>
                  <span>№</span>
                </span>
              ) : (
                <span>
                  <b>!</b>
                  <span>1</span>
                </span>
              )}
            </a>
          ) : (
            <a className="key c49"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("50"))) ? (
            <a
              href="#"
              className="key c50"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("50")
                  ? "yellow"
                  : ctxfast.state.errbutton.includes("50") && "red",
              }}
            >
              {ctxfast.state.keyboardlang == "MON" ? (
                <span>
                  <b>2</b>
                  <span>-</span>
                </span>
              ) : (
                <span>
                  <b>@</b>
                  <span>2</span>
                </span>
              )}
            </a>
          ) : (
            <a className="key c50"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("51"))) ? (
            <a
              href="#"
              className="key c51"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("51")
                  ? "hotpink"
                  : ctxfast.state.errbutton.includes("51") && "red",
              }}
            >
              {ctxfast.state.keyboardlang == "MON" ? (
                <span>
                  <b>3</b>
                  <span>"</span>
                </span>
              ) : (
                <span>
                  <b>#</b>
                  <span>3</span>
                </span>
              )}
            </a>
          ) : (
            <a className="key c51"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("52"))) ? (
            <a
              href="#"
              className="key c52"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("52")
                  ? "#66ffff"
                  : ctxfast.state.errbutton.includes("52") && "red",
              }}
            >
              {ctxfast.state.keyboardlang == "MON" ? (
                <span>
                  <b>4</b>
                  <span>₮</span>
                </span>
              ) : (
                <span>
                  <b>$</b>
                  <span>4</span>
                </span>
              )}
            </a>
          ) : (
            <a className="key c52"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("53"))) ? (
            <a
              href="#"
              className="key c53"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("53")
                  ? "#66ffff"
                  : ctxfast.state.errbutton.includes("53") && "red",
              }}
            >
              {ctxfast.state.keyboardlang == "MON" ? (
                <span>
                  <b>5</b>
                  <span>:</span>
                </span>
              ) : (
                <span>
                  <b>%</b>
                  <span>5</span>
                </span>
              )}
            </a>
          ) : (
            <a className="key c53"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("54"))) ? (
            <a
              href="#"
              className="key c54"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("54")
                  ? "#b3ff66"
                  : ctxfast.state.errbutton.includes("54") && "red",
              }}
            >
              {ctxfast.state.keyboardlang == "MON" ? (
                <span>
                  <b>6</b>
                  <span>.</span>
                </span>
              ) : (
                <span>
                  <b>^</b>
                  <span>6</span>
                </span>
              )}
            </a>
          ) : (
            <a className="key c54"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("55"))) ? (
            <a
              href="#"
              className="key c55"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("55")
                  ? "#b3ff66"
                  : ctxfast.state.errbutton.includes("55") && "red",
              }}
            >
              {ctxfast.state.keyboardlang == "MON" ? (
                <span>
                  <b>7</b>
                  <span>_</span>
                </span>
              ) : (
                <span>
                  <b>&amp;</b>
                  <span>7</span>
                </span>
              )}
            </a>
          ) : (
            <a className="key c55"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("56"))) ? (
            <a
              href="#"
              className="key c56"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("56")
                  ? "hotpink"
                  : ctxfast.state.errbutton.includes("56") && "red",
              }}
            >
              {ctxfast.state.keyboardlang == "MON" ? (
                <span>
                  <b>8</b>
                  <span>,</span>
                </span>
              ) : (
                <span>
                  <b>*</b>
                  <span>8</span>
                </span>
              )}
            </a>
          ) : (
            <a className="key c56"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("57"))) ? (
            <a
              href="#"
              className="key c57"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("57")
                  ? "yellow"
                  : ctxfast.state.errbutton.includes("57") && "red",
              }}
            >
              {ctxfast.state.keyboardlang == "MON" ? (
                <span>
                  <b>9</b>
                  <span>%</span>
                </span>
              ) : (
                <span>
                  <b>(</b>
                  <span>9</span>
                </span>
              )}
            </a>
          ) : (
            <a className="key c57"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("48"))) ? (
            <a
              href="#"
              className="key c48"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("48")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("48") && "red",
              }}
            >
              {ctxfast.state.keyboardlang == "MON" ? (
                <span>
                  <b>0</b>
                  <span>?</span>
                </span>
              ) : (
                <span>
                  <b>)</b>
                  <span>0</span>
                </span>
              )}
            </a>
          ) : (
            <a className="key c48"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("189"))) ? (
            ctxfast.state.keyboardlang == "MON" ? (
              <a
                href="#"
                className="key"
                id="worde"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("189")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("189") && "red",
                }}
              >
                <span>е</span>
              </a>
            ) : (
              <a
                href="#"
                className="key c189 alt"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("189")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("189") && "red",
                }}
              >
                <b>_</b>
                <span>-</span>{" "}
              </a>
            )
          ) : (
            <a className="key" id="worde"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("187"))) ? (
            ctxfast.state.keyboardlang == "MON" ? (
              <a
                href="#"
                className="key"
                id="worde"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("187")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("187") && "red",
                }}
              >
                <span>щ</span>
              </a>
            ) : (
              <a
                href="#"
                className="key c187 alt"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("187")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("187") && "red",
                }}
              >
                <span>
                  <b>+</b>
                  <span>=</span>
                </span>
              </a>
            )
          ) : (
            <a className="key" id="worde"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("46"))) ? (
            <a
              href="#"
              className="key c46"
              id="delete"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("46")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("46") && "red",
              }}
            >
              <span>{isIOS ? "delete" : "backspace"}</span>
            </a>
          ) : (
            <a className="key c46" id="delete"></a>
          )}
        </li>
      </ul>
      <ul className="cf" id="qwerty">
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("9"))) ? (
            <a
              href="#"
              className="key c9"
              id="tab"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("9")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("9") && "red",
              }}
            >
              <span>tab</span>
            </a>
          ) : (
            <a className="key c9" id="tab"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("81"))) ? (
            <a
              href="#"
              className="key c81"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("81")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("81") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "ф" : "q"}</span>
            </a>
          ) : (
            <a className="key c81"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("87"))) ? (
            <a
              href="#"
              className="key c87"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("87")
                  ? "yellow"
                  : ctxfast.state.errbutton.includes("87") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "ц" : "w"}</span>
            </a>
          ) : (
            <a className="key c87"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("69"))) ? (
            <a
              href="#"
              className="key c69"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("69")
                  ? "hotpink"
                  : ctxfast.state.errbutton.includes("69") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "у" : "e"}</span>
            </a>
          ) : (
            <a className="key c69"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("82"))) ? (
            <a
              href="#"
              className="key c82"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("82")
                  ? "#66ffff"
                  : ctxfast.state.errbutton.includes("82") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "ж" : "r"}</span>
            </a>
          ) : (
            <a className="key c82"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("87"))) ? (
            <a
              href="#"
              className="key c84"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("84")
                  ? "#66ffff"
                  : ctxfast.state.errbutton.includes("84") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "э" : "t"}</span>
            </a>
          ) : (
            <a className="key c84"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("89"))) ? (
            <a
              href="#"
              className="key c89"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("89")
                  ? "#b3ff66"
                  : ctxfast.state.errbutton.includes("89") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "н" : "y"}</span>
            </a>
          ) : (
            <a className="key c89"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("85"))) ? (
            <a
              href="#"
              className="key c85"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("85")
                  ? "#b3ff66"
                  : ctxfast.state.errbutton.includes("85") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "г" : "u"}</span>
            </a>
          ) : (
            <a className="key c85"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("73"))) ? (
            <a
              href="#"
              className="key c73"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("73")
                  ? "hotpink"
                  : ctxfast.state.errbutton.includes("73") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "ш" : "i"}</span>
            </a>
          ) : (
            <a className="key c73"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("79"))) ? (
            <a
              href="#"
              className="key c79"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("79")
                  ? "yellow"
                  : ctxfast.state.errbutton.includes("79") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "ү" : "o"}</span>
            </a>
          ) : (
            <a className="key c79"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("80"))) ? (
            <a
              href="#"
              className="key c80"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("80")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("80") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "з" : "p"}</span>
            </a>
          ) : (
            <a className="key c80"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("219"))) ? (
            ctxfast.state.keyboardlang == "MON" ? (
              <a
                href="#"
                className="key"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("219")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("219") && "red",
                }}
              >
                <span>к</span>
              </a>
            ) : (
              <a
                href="#"
                className="key c219 alt"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("219")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("219") && "red",
                }}
              >
                <b>{"{"}</b>
                <span>[</span>
              </a>
            )
          ) : (
            <a className="key c219 alt"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("221"))) ? (
            ctxfast.state.keyboardlang == "MON" ? (
              <a
                href="#"
                className="key"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("221")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("221") && "red",
                }}
              >
                <span>ъ</span>
              </a>
            ) : (
              <a
                href="#"
                className="key c221 alt"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("221")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("221") && "red",
                }}
              >
                <b>{"}"}</b>
                <span>]</span>
              </a>
            )
          ) : (
            <a className="key c221 alt"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("220"))) ? (
            <a
              href="#"
              className="key c220 alt"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("220")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("220") && "red",
              }}
            >
              <b>|</b>
              <span>\</span>
            </a>
          ) : (
            <a className="key c220 alt"></a>
          )}
        </li>
      </ul>
      <ul className="cf" id="asdfg">
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("20"))) ? (
            <a
              href="#"
              className="key c20 alt"
              id="caps"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("20")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("20") && "red",
              }}
            >
              <b />
              <span>caps lock</span>
            </a>
          ) : (
            <a className="key c20 alt" id="caps"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("65"))) ? (
            <a
              href="#"
              className="key c65"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("65")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("65") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "й" : "a"}</span>
            </a>
          ) : (
            <a className="key c65"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("83"))) ? (
            <a
              href="#"
              className="key c83"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("83")
                  ? "yellow"
                  : ctxfast.state.errbutton.includes("83") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "ы" : "s"}</span>
            </a>
          ) : (
            <a className="key c83"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("68"))) ? (
            <a
              href="#"
              className="key c68"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("68")
                  ? "hotpink"
                  : ctxfast.state.errbutton.includes("68") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "б" : "d"}</span>
            </a>
          ) : (
            <a className="key c68"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("70"))) ? (
            <a
              href="#"
              className="key c70"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("70")
                  ? "#66ffff"
                  : ctxfast.state.errbutton.includes("70") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "ө" : "f"}</span>
            </a>
          ) : (
            <a className="key c70"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("71"))) ? (
            <a
              href="#"
              className="key c71"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("71")
                  ? "#66ffff"
                  : ctxfast.state.errbutton.includes("71") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "а" : "g"}</span>
            </a>
          ) : (
            <a className="key c71"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("72"))) ? (
            <a
              href="#"
              className="key c72"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("72")
                  ? "#b3ff66"
                  : ctxfast.state.errbutton.includes("72") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "х" : "h"}</span>
            </a>
          ) : (
            <a className="key c72"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("74"))) ? (
            <a
              href="#"
              className="key c74"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("74")
                  ? "#b3ff66"
                  : ctxfast.state.errbutton.includes("74") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "р" : "j"}</span>
            </a>
          ) : (
            <a className="key c74"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("75"))) ? (
            <a
              href="#"
              className="key c75"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("75")
                  ? "hotpink"
                  : ctxfast.state.errbutton.includes("75") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "о" : "k"}</span>
            </a>
          ) : (
            <a className="key c75"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("76"))) ? (
            <a
              href="#"
              className="key c76"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("76")
                  ? "yellow"
                  : ctxfast.state.errbutton.includes("76") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "л" : "l"}</span>
            </a>
          ) : (
            <a className="key c76"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("186"))) ? (
            ctxfast.state.keyboardlang == "MON" ? (
              <a
                href="#"
                className="key"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("186")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("186") && "red",
                }}
              >
                <span>д</span>
              </a>
            ) : (
              <a
                href="#"
                className="key c186 alt"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("186")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("186") && "red",
                }}
              >
                <b>:</b>
                <span>;</span>
              </a>
            )
          ) : (
            <a className="key c186 alt"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("222"))) ? (
            ctxfast.state.keyboardlang == "MON" ? (
              <a
                href="#"
                className="key"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("222")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("222") && "red",
                }}
              >
                <span>п</span>
              </a>
            ) : (
              <a
                href="#"
                className="key c222 alt"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("222")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("222") && "red",
                }}
              >
                <b>"</b>
                <span>'</span>
              </a>
            )
          ) : (
            <a className="key c222 alt"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("13"))) ? (
            <a
              href="#"
              className="key c13 alt"
              id="enter"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("13")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("13") && "red",
              }}
            >
              <span>{isIOS ? "return" : "enter"}</span>
            </a>
          ) : (
            <a className="key c13 alt" id="enter"></a>
          )}
        </li>
      </ul>
      <ul className="cf" id="zxcvb">
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("16"))) ? (
            <a
              href="#"
              className="key c16 shiftleft"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("16")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("16") && "red",
              }}
            >
              <span>Shift</span>
            </a>
          ) : (
            <a className="key c16 shiftleft"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("90"))) ? (
            <a
              href="#"
              className="key c90"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("90")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("90") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "я" : "z"}</span>
            </a>
          ) : (
            <a className="key c90"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("88"))) ? (
            <a
              href="#"
              className="key c88"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("88")
                  ? "yellow"
                  : ctxfast.state.errbutton.includes("88") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "ч" : "x"}</span>
            </a>
          ) : (
            <a className="key c88"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("67"))) ? (
            <a
              href="#"
              className="key c67"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("67")
                  ? "hotpink"
                  : ctxfast.state.errbutton.includes("67") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "ё" : "c"}</span>
            </a>
          ) : (
            <a className="key c67"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("86"))) ? (
            <a
              href="#"
              className="key c86"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("86")
                  ? "#66ffff"
                  : ctxfast.state.errbutton.includes("86") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "с" : "v"}</span>
            </a>
          ) : (
            <a className="key c86"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("66"))) ? (
            <a
              href="#"
              className="key c66"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("66")
                  ? "#66ffff"
                  : ctxfast.state.errbutton.includes("66") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "м" : "b"}</span>
            </a>
          ) : (
            <a className="key c66"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("78"))) ? (
            <a
              href="#"
              className="key c78"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("78")
                  ? "#b3ff66"
                  : ctxfast.state.errbutton.includes("78") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "и" : "n"}</span>
            </a>
          ) : (
            <a className="key c78"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("77"))) ? (
            <a
              href="#"
              className="key c77"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("77")
                  ? "#b3ff66"
                  : ctxfast.state.errbutton.includes("77") && "red",
              }}
            >
              <span>{ctxfast.state.keyboardlang == "MON" ? "т" : "m"}</span>
            </a>
          ) : (
            <a className="key c77"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("188"))) ? (
            ctxfast.state.keyboardlang == "MON" ? (
              <a
                href="#"
                className="key"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("188")
                    ? "hotpink"
                    : ctxfast.state.errbutton.includes("188") && "red",
                }}
              >
                <span>ь</span>
              </a>
            ) : (
              <a
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("188")
                    ? "hotpink"
                    : ctxfast.state.errbutton.includes("188") && "red",
                }}
                href="#"
                className="key c188 alt"
              >
                <b>&lt;</b>
                <span>,</span>
              </a>
            )
          ) : (
            <a href="#" className="key c188 alt"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("188"))) ? (
            ctxfast.state.keyboardlang == "MON" ? (
              <a
                href="#"
                className="key"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("190")
                    ? "yellow"
                    : ctxfast.state.errbutton.includes("190") && "red",
                }}
              >
                <span>в</span>
              </a>
            ) : (
              <a
                href="#"
                className="key c190 alt"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("190")
                    ? "yellow"
                    : ctxfast.state.errbutton.includes("190") && "red",
                }}
              >
                <b>&gt;</b>
                <span>.</span>
              </a>
            )
          ) : (
            <a href="#" className="key c188 alt"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("191"))) ? (
            ctxfast.state.keyboardlang == "MON" ? (
              <a
                href="#"
                className="key"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("191")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("191") && "red",
                }}
              >
                <span>ю</span>
              </a>
            ) : (
              <a
                href="#"
                className="key c191 alt"
                style={{
                  color: "black",
                  backgroundColor: ctxfast.state.tipbutton.includes("191")
                    ? "orange"
                    : ctxfast.state.errbutton.includes("191") && "red",
                }}
              >
                <b>?</b>
                <span>/</span>
              </a>
            )
          ) : (
            <a className="key c191 alt"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("1600"))) ? (
            <a
              href="#"
              className="key c16 shiftright"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("1600")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("1600") && "red",
              }}
            >
              <span>Shift</span>
            </a>
          ) : (
            <a className="key c16 shiftright"></a>
          )}
        </li>
      </ul>
      <ul className="cf" id="bottomrow">
        <li style={{ float: "left" }}>
          {ctxfast.state.showword == 1 ? (
            <a
              href="#"
              className="key"
              id="fn"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("fn")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("fn") && "red",
              }}
            >
              <span>fn</span>
            </a>
          ) : (
            <a className="key" id="fn"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword == 1 ? (
            <a
              href="#"
              className="key c17"
              id="control"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("17")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("17") && "red",
              }}
            >
              <span>{isIOS ? "control" : "Ctrl"}</span>
            </a>
          ) : (
            <a className="key c17" id="control"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword == 1 ? (
            <a
              href="#"
              className="key option"
              id="optionleft"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("optionleft")
                  ? "orange"
                  : ctxfast.state.errbutton.includes("optionleft") && "red",
              }}
            >
              <span>{isIOS ? "option" : "win"}</span>
            </a>
          ) : (
            <a className="key option" id="optionleft"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword == 1 ? (
            <a
              href="#"
              className="key command"
              id="commandleft"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("commandleft")
                  ? "yellow"
                  : ctxfast.state.errbutton.includes("commandleft") && "red",
              }}
            >
              <span>{isIOS ? "command" : "alt"}</span>
            </a>
          ) : (
            <a className="key command" id="commandleft"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword != 2 &&
          (ctxfast.state.showword == 1 ||
            (ctxfast.state.showword == 3 &&
              ctxfast.state.usebutton.includes("32"))) ? (
            <a
              onClick={() => props.handleStart()}
              className="key c32"
              id="spacebar"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("32")
                  ? "#E9DEDA"
                  : ctxfast.state.errbutton.includes("32") && "red",
              }}
            >
              {!ctxfast.state.started ? (
                <span>Эхлэх</span>
              ) : (
                <span>Дахин эхлүүлэх</span>
              )}
            </a>
          ) : (
            <a
              onClick={() => props.handleStart()}
              className="key c32"
              id="spacebar"
            >
              {!ctxfast.state.started ? (
                <span>Эхлэх</span>
              ) : (
                <span>Дахин эхлүүлэх</span>
              )}
            </a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword == 1 ? (
            <a
              href="#"
              className="key command"
              id="commandright"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes(
                  "commandright"
                )
                  ? "hotpink"
                  : ctxfast.state.errbutton.includes("commandright") && "red",
              }}
            >
              <span>{isIOS ? "command" : "alt"}</span>
            </a>
          ) : (
            <a className="key command" id="commandright"></a>
          )}
        </li>
        <li style={{ float: "left" }}>
          {ctxfast.state.showword == 1 ? (
            <a
              href="#"
              className="key option"
              id="optionright"
              style={{
                color: "black",
                backgroundColor: ctxfast.state.tipbutton.includes("optionright")
                  ? "yellow"
                  : ctxfast.state.errbutton.includes("optionright") && "red",
              }}
            >
              <span>{isIOS ? "option" : "win"}</span>
            </a>
          ) : (
            <a className="key option" id="optionright"></a>
          )}
        </li>
        <ol className="cf">
          <Hand />
        </ol>
      </ul>
    </div>
  );
};

export default MacKeyboard;
