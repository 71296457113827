export const keyReplacer = (language, key) => {
  let newkey = key;
  if (language === "MON") {
    switch (key) {
      case " ":
        newkey = " ";
        break;
      //   35 үсэг дууслаа
      case "q":
        newkey = "ф";
        break;
      case "w":
        newkey = "ц";
        break;
      case "e":
        newkey = "у";
        break;
      case "r":
        newkey = "ж";
        break;
      case "t":
        newkey = "э";
        break;
      case "y":
        newkey = "н";
        break;
      case "u":
        newkey = "г";
        break;
      case "i":
        newkey = "ш";
        break;
      case "o":
        newkey = "ү";
        break;
      case "p":
        newkey = "з";
        break;
      case "[":
        newkey = "к";
        break;
      case "]":
        newkey = "ъ";
        break;
      case "a":
        newkey = "й";
        break;
      case "s":
        newkey = "ы";
        break;
      case "d":
        newkey = "б";
        break;
      case "f":
        newkey = "ө";
        break;
      case "g":
        newkey = "а";
        break;
      case "h":
        newkey = "х";
        break;
      case "j":
        newkey = "р";
        break;
      case "k":
        newkey = "о";
        break;
      case "l":
        newkey = "л";
        break;
      case ";":
        newkey = "д";
        break;
      case "'":
        newkey = "п";
        break;
      case "z":
        newkey = "я";
        break;
      case "x":
        newkey = "ч";
        break;
      case "c":
        newkey = "ё";
        break;
      case "v":
        newkey = "с";
        break;
      case "b":
        newkey = "м";
        break;
      case "n":
        newkey = "и";
        break;
      case "m":
        newkey = "т";
        break;
      case ",":
        newkey = "ь";
        break;
      case ".":
        newkey = "в";
        break;
      case "/":
        newkey = "ю";
        break;
      case "Q":
        newkey = "Ф";
        break;
      case "W":
        newkey = "Ц";
        break;
      case "E":
        newkey = "У";
        break;
      case "R":
        newkey = "Ж";
        break;
      case "T":
        newkey = "Э";
        break;
      case "Y":
        newkey = "Н";
        break;
      case "U":
        newkey = "Г";
        break;
      case "I":
        newkey = "Ш";
        break;
      case "O":
        newkey = "Ү";
        break;
      case "P":
        newkey = "З";
        break;
      case "{":
        newkey = "К";
        break;
      case "}":
        newkey = "Ъ";
        break;
      case "A":
        newkey = "Й";
        break;
      case "S":
        newkey = "Ы";
        break;
      case "D":
        newkey = "Б";
        break;
      case "F":
        newkey = "Ө";
        break;
      case "G":
        newkey = "А";
        break;
      case "H":
        newkey = "Х";
        break;
      case "J":
        newkey = "Р";
        break;
      case "K":
        newkey = "О";
        break;
      case "L":
        newkey = "Л";
        break;
      case `:`:
        newkey = "Д";
        break;
      case `"`:
        newkey = "П";
        break;
      case "Z":
        newkey = "Я";
        break;
      case "X":
        newkey = "Ч";
        break;
      case "C":
        newkey = "Ё";
        break;
      case "V":
        newkey = "С";
        break;
      case "B":
        newkey = "М";
        break;
      case "N":
        newkey = "И";
        break;
      case "M":
        newkey = "Т";
        break;
      case "<":
        newkey = "Ь";
        break;
      case ">":
        newkey = "В";
        break;
      case "?":
        newkey = "Ю";
        break;
      case "-":
        newkey = "е";
        break;
      case "_":
        newkey = "Е";
        break;
      case "=":
        newkey = "щ";
        break;
      case "+":
        newkey = "Щ";
        break;

      // Too
      case "!":
        newkey = "1";
        break;
      case "@":
        newkey = "2";
        break;
      case "#":
        newkey = "3";
        break;
      case "$":
        newkey = "4";
        break;
      case "%":
        newkey = "5";
        break;
      case "^":
        newkey = "6";
        break;
      case "&":
        newkey = "7";
        break;
      case "*":
        newkey = "8";
        break;
      case "(":
        newkey = "9";
        break;
      case ")":
        newkey = "0";
        break;
      //   Shiftгүй too

      case "1":
        newkey = "№";
        break;
      case "2":
        newkey = "-";
        break;
      case `3`:
        newkey = `"`;
        break;
      case "4":
        newkey = "₮";
        break;
      case "5":
        newkey = ":";
        break;
      case "6":
        newkey = ".";
        break;
      case "7":
        newkey = "_";
        break;
      case "8":
        newkey = ",";
        break;
      case "9":
        newkey = "%";
        break;
      case "0":
        newkey = "?";
        break;
      // Үлдсэн товч
      case "`":
        newkey = "=";
        break;
      case "~":
        newkey = "+";
        break;
      case "|":
        newkey = "|";
        break;
      default:
        newkey = key;
    }
  } else {
    switch (key) {
      case " ":
        newkey = " ";
        break;
      //   35 үсэг дууслаа
      case "ф":
        newkey = "q";
        break;
      case "ц":
        newkey = "w";
        break;
      case "у":
        newkey = "e";
        break;
      case "ж":
        newkey = "r";
        break;
      case "э":
        newkey = "t";
        break;
      case "н":
        newkey = "y";
        break;
      case "г":
        newkey = "u";
        break;
      case "ш":
        newkey = "i";
        break;
      case "ү":
        newkey = "o";
        break;
      case "з":
        newkey = "p";
        break;
      case "к":
        newkey = "[";
        break;
      case "ъ":
        newkey = "]";
        break;
      case "й":
        newkey = "a";
        break;
      case "ы":
        newkey = "s";
        break;
      case "б":
        newkey = "d";
        break;
      case "ө":
        newkey = "f";
        break;
      case "а":
        newkey = "g";
        break;
      case "х":
        newkey = "h";
        break;
      case "р":
        newkey = "j";
        break;
      case "о":
        newkey = "k";
        break;
      case "л":
        newkey = "l";
        break;
      case "д":
        newkey = ";";
        break;
      case "п":
        newkey = "'";
        break;
      case "я":
        newkey = "z";
        break;
      case "ч":
        newkey = "x";
        break;
      case "ё":
        newkey = "c";
        break;
      case "с":
        newkey = "v";
        break;
      case "м":
        newkey = "b";
        break;
      case "и":
        newkey = "n";
        break;
      case "т":
        newkey = "m";
        break;
      case "ь":
        newkey = ",";
        break;
      case "в":
        newkey = ".";
        break;
      case "ю":
        newkey = "/";
        break;
      case "Ф":
        newkey = "Q";
        break;
      case "Ц":
        newkey = "W";
        break;
      case "У":
        newkey = "E";
        break;
      case "Ж":
        newkey = "R";
        break;
      case "Э":
        newkey = "T";
        break;
      case "Н":
        newkey = "Y";
        break;
      case "Г":
        newkey = "U";
        break;
      case "Ш":
        newkey = "I";
        break;
      case "Ү":
        newkey = "O";
        break;
      case "З":
        newkey = "P";
        break;
      case "К":
        newkey = "{";
        break;
      case "Ъ":
        newkey = "}";
        break;
      case "Й":
        newkey = "A";
        break;
      case "Ы":
        newkey = "S";
        break;
      case "Б":
        newkey = "D";
        break;
      case "Ө":
        newkey = "F";
        break;
      case "А":
        newkey = "G";
        break;
      case "Х":
        newkey = "H";
        break;
      case "Р":
        newkey = "J";
        break;
      case "О":
        newkey = "K";
        break;
      case "Л":
        newkey = "L";
        break;
      case "Д":
        newkey = ":";
        break;
      case "П":
        newkey = `"`;
        break;
      case "Я":
        newkey = "Z";
        break;
      case "Ч":
        newkey = "X";
        break;
      case "Ё":
        newkey = "C";
        break;
      case "С":
        newkey = "V";
        break;
      case "М":
        newkey = "B";
        break;
      case "И":
        newkey = "N";
        break;
      case "Т":
        newkey = "M";
        break;
      case "Ь":
        newkey = "<";
        break;
      case "В":
        newkey = ">";
        break;
      case "Ю":
        newkey = "?";
        break;
      case "е":
        newkey = "-";
        break;
      case "Е":
        newkey = "_";
        break;
      case "щ":
        newkey = "=";
        break;
      case "Щ":
        newkey = "+";
        break;

      // Too
      case "1":
        newkey = "!";
        break;
      case "2":
        newkey = "@";
        break;
      case "3":
        newkey = "#";
        break;
      case "4":
        newkey = "$";
        break;
      case "5":
        newkey = "%";
        break;
      case "6":
        newkey = "^";
        break;
      case "7":
        newkey = "&";
        break;
      case "8":
        newkey = "*";
        break;
      case "9":
        newkey = "(";
        break;
      case "0":
        newkey = ")";
        break;
      //   Shiftгүй too

      case "№":
        newkey = "1";
        break;
      case "-":
        newkey = "2";
        break;
      case `"`:
        newkey = "3";
        break;
      case "₮":
        newkey = "4";
        break;
      case ":":
        newkey = "5";
        break;
      case ".":
        newkey = "6";
        break;
      case "_":
        newkey = "7";
        break;
      case ",":
        newkey = "8";
        break;
      case "%":
        newkey = "9";
        break;
      case "?":
        newkey = "0";
        break;
      // Үлдсэн товч
      case "=":
        newkey = "`";
        break;
      case "+":
        newkey = "~";
        break;
      case "|":
        newkey = "|";
        break;
      default:
        newkey = key;
    }
  }
  return newkey;
};
