import React, { useContext } from "react";
import {
  Form,
  Select,
  InputNumber,
  Switch,
  Radio,
  Slider,
  Button,
  Upload,
  Rate,
  Checkbox,
  Row,
  Col,
} from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import FastContext from "../../context/FastContext";
const { Option } = Select;

export default function SettingForm() {
  const ctxfast = useContext(FastContext);

  function onChange(checked) {
    if (checked) {
      ctxfast.changeState("casesensitive", true);
    } else {
      ctxfast.changeState("casesensitive", false);
    }
  }
  function onChangeSound(checked) {
    if (checked) {
      ctxfast.changeState("playsound", true);
    } else {
      ctxfast.changeState("playsound", false);
    }
  }
  function onChangeTime(value) {
    sessionStorage.setItem("hugatsaa", value * 1000);
    ctxfast.changeState("hugatsaa", value * 1000);
  }
  const onChangeWord = (e) => {
    ctxfast.changeState("showword", e.target.value);
  };

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 10 }}
      name="validate_other"
      initialValues={{
        ["input-number"]: 3,
        ["checkbox-group"]: ["A", "B"],
        rate: 3.5,
      }}
    >
      <Form.Item label="Том, жижиг үсэг">
        <Switch value={ctxfast.state.casesensitive} onChange={onChange} />
      </Form.Item>
      <Form.Item label="Дуу нээх, хаах">
        <Switch value={ctxfast.state.playsound} onChange={onChangeSound} />
      </Form.Item>
      <Form.Item name="radio-group" label="Компьютерийн гарын үсэг">
        <Radio.Group onChange={onChangeWord} value={ctxfast.state.showword}>
          <Radio value={1}>Бүх үсгээ харна</Radio>
          <Radio value={3}>Хэрэглэх үсгээ харна</Radio>
          <Radio value={2}>Үсгээ харахгүй</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Дасгал хийх хугацаа">
        <InputNumber
          value={Math.floor(ctxfast.state.hugatsaa / 1000)}
          min={10}
          max={300}
          size="small"
          formatter={(value) => `${value}`}
          parser={(value) => value.replace("сек", "")}
          onChange={onChangeTime}
          type="number"
        />
        сек
      </Form.Item>
    </Form>
  );
}
