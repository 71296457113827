import React from "react";
import { Image, Button } from "antd";

import MacKeyboard from "../MacKeyboard/MacKeyboard";
const MyKeyBoard = (props) => {
  
  return (
    <div>
      <MacKeyboard handleStart={props.handleStart} />
    </div>
  );
};
export default MyKeyBoard;
