import React, { useContext, useEffect } from "react";
import CustomerContext from "../context/CustomerContext";
import { Link } from "react-router-dom";
import {
  Layout,
  Row,
  Col,
  Space,
  Select,
  Input,
  Button,
  Menu,
  Dropdown,
} from "antd";
import "antd/dist/antd.css";
import CustomerPagination from "../components/Customer/CustomerPagination";
import CustomerTable from "../components/Customer/CustomerTable";
import CustomerModal from "../components/Customer/CustomerModal";
const { Header, Footer, Sider, Content } = Layout;
const { Option } = Select;
const { Search } = Input;
let listpage = [];
export default function AdminPage() {
  const ctxcustomer = useContext(CustomerContext);
  useEffect(() => {
    ctxcustomer.loadLast50data();
  }, []);

  const onSearch = (value) => {
    ctxcustomer.filterCustomer(value);
  };

  for (let i = 1; i <= ctxcustomer.state.pageCount; i++) {
    !listpage.includes(i) && listpage.push(i);
  }
  return (
    <Layout>
      <Header>
        <span style={{ color: "white", fontSize: "20px", paddingTop: "20px" }}>
          Удирдлагын хэсэг
        </span>
        <Link to="/">Үндсэн хуудасруу буцах</Link>{" "}
      </Header>
      <Content>
        <CustomerModal />
        <Search
          size="small"
          placeholder="Ерөнхий хайлт"
          allowClear
          onSearch={onSearch}
          style={{ width: 200, margin: "0 10px" }}
          value={ctxcustomer.state.filtervalue}
          onChange={(event) =>
            ctxcustomer.changeState("filtervalue", event.target.value)
          }
        />
        <CustomerPagination />
        <CustomerTable />
      </Content>
      <Footer>2021 он</Footer>
    </Layout>
  );
}
