import {
  mon3LetterBig,
  mon3Lettersmall,
  mon4LetterSmall,
  mon4LetterBig,
  mon5LetterBig,
  mon5LetterSmall,
  monUguulber,
  eng3LetterBig,
  eng3Lettersmall,
  eng4LetterSmall,
  eng4LetterBig,
  eng5LetterBig,
  eng5LetterSmall,
  engUguulber,
} from "./MyTextLibrary";
// Бичих текстийг үүсгэгч
export const textGenerator = (lessonlang, lessonid, casesensitive) => {
  let result = "";
  const length = 500;
  let charactersLength;
  let characters = "абвгдеёжзийклмноөпрстуүхфцчшщъьыэюя1234567890";
  let wordarray = ["нар", "сар"];
  if (lessonlang == "MON") {
    if (lessonid == 10) {
      casesensitive
        ? (characters = "йыбөролдЙЫБӨРОЛД")
        : (characters = "йыбөролд");
      charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result +=
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          " ";
      }
    } else if (lessonid == 20) {
      characters = "йыбөролдфцужэгшүзк⏎";
      charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result +=
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          " ";
      }
    } else if (lessonid == 30) {
      characters = "";
      casesensitive
        ? (characters = "йыбөролднахмъпЙЫБӨРОЛДНАХМЪП⏎")
        : (characters = "йыбөролднахмъп⏎");
      charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result +=
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          " ";
      }
    } else if (lessonid == 40) {
      casesensitive
        ? (characters = "йыбөролдячёситьвю⏎ЙЫБӨРОЛДЯЧЁСИТЬВЮ")
        : (characters = "йыбөролдячёситьвю⏎");
      charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result +=
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          " ";
      }
    } else if (lessonid == 60) {
      casesensitive
        ? (characters = `йыбөролд⇥⇠⏎№-"₮:._,%?ещ=+1234567890ЕЩ|`)
        : (characters = `йыбөролд⇥⇠⏎№-"₮:._,%?ещ=`);
      charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result +=
          characters.charAt(Math.floor(Math.random() * charactersLength)) + "";
      }
    } else if (lessonid == 50) {
      casesensitive
        ? (characters = `абвгдеёжзийклмноөпрстуүхфцчшщъьыэюяАБВГДЕЁЖЗИЙКЛМНОӨПРСТУҮХФЦЧШЩЪЫЬЭЮЯ`)
        : (characters = `абвгдеёжзийклмноөпрстуүхфцчшщъьыэюя`);
      charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result +=
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          " ";
      }
    } else if (lessonid == 310) {
      casesensitive
        ? (wordarray = mon3LetterBig)
        : (wordarray = mon3Lettersmall);
      charactersLength = wordarray.length;
      for (var i = 0; i < length; i++) {
        result += wordarray[Math.floor(Math.random() * charactersLength)] + " ";
      }
    } else if (lessonid == 320) {
      casesensitive
        ? (wordarray = mon4LetterBig)
        : (wordarray = mon4LetterSmall);
      charactersLength = wordarray.length;
      for (var i = 0; i < length; i++) {
        result += wordarray[Math.floor(Math.random() * charactersLength)] + " ";
      }
    } else if (lessonid == 330) {
      casesensitive
        ? (wordarray = mon5LetterBig)
        : (wordarray = mon5LetterSmall);
      charactersLength = wordarray.length;
      for (var i = 0; i < length; i++) {
        result += wordarray[Math.floor(Math.random() * charactersLength)] + " ";
      }
    } else if (lessonid == 340) {
      wordarray = monUguulber;
      charactersLength = wordarray.length;
      for (var i = 0; i < length; i++) {
        result += wordarray[Math.floor(Math.random() * charactersLength)] + " ";
      }
    }
    return result;
  } else if (lessonlang == "ENG") {
    if (lessonid == 10) {
      casesensitive
        ? (characters = "asdfjklASDFJKL")
        : (characters = "asdfjkl");
      charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result +=
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          " ";
      }
    } else if (lessonid == 20) {
      casesensitive
        ? (characters = "qwertasdfuiopjklQWERTASDFUIOPJKL⏎")
        : (characters = "qwertasdfuiopjkl⏎");
      charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result +=
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          " ";
      }
    } else if (lessonid == 30) {
      characters = "";
      casesensitive
        ? (characters = "asdfghjkltybASDFGHJKLTYB⏎")
        : (characters = "asdfghjkltyb⏎");
      charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result +=
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          " ";
      }
    } else if (lessonid == 40) {
      casesensitive
        ? (characters = "zxcvnmasdfjklZXCVNMASDFJKL⏎")
        : (characters = "zxcvnmasdfjkl⏎");
      charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result +=
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          " ";
      }
    } else if (lessonid == 60) {
      casesensitive
        ? (characters = `asdfjkl⇥⇠⏎,<.>/?;:'"~!@#$%^&*()_+1234567890-=[]{}|`)
        : (characters = `asdfjkl;'1234567890-=⇠,./[]`);
      charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result +=
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          " ";
      }
    } else if (lessonid == 50) {
      casesensitive
        ? (characters = `abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ⏎`)
        : (characters = `abcdefghijklmnopqrstuvwxyz⏎`);
      charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result +=
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          characters.charAt(Math.floor(Math.random() * charactersLength)) +
          " ";
      }
    } else if (lessonid == 310) {
      casesensitive
        ? (wordarray = eng3LetterBig)
        : (wordarray = eng3Lettersmall);
      charactersLength = wordarray.length;
      for (var i = 0; i < length; i++) {
        result += wordarray[Math.floor(Math.random() * charactersLength)] + " ";
      }
    } else if (lessonid == 320) {
      casesensitive
        ? (wordarray = eng4LetterBig)
        : (wordarray = eng4LetterSmall);
      charactersLength = wordarray.length;
      for (var i = 0; i < length; i++) {
        result += wordarray[Math.floor(Math.random() * charactersLength)] + " ";
      }
    } else if (lessonid == 330) {
      casesensitive
        ? (wordarray = eng5LetterBig)
        : (wordarray = eng5LetterSmall);
      charactersLength = wordarray.length;
      for (var i = 0; i < length; i++) {
        result += wordarray[Math.floor(Math.random() * charactersLength)] + " ";
      }
    } else if (lessonid == 340) {
      wordarray = engUguulber;
      charactersLength = wordarray.length;
      for (var i = 0; i < length; i++) {
        result += wordarray[Math.floor(Math.random() * charactersLength)] + " ";
      }
    }
    return result;
  }
};
//Ямар товч дарахыг шаардаж байна. Түүний өнгийг харуулах
export const tipgenerator = (language, key) => {
  let tip = ["32"];
  if (language == "MON") {
    tip = getMonKeyCodeArray(key);
  } else {
    tip = getEngKeyCodeArray(key);
  }
  return tip;
};
// Эхлэхийн өмнө тухайн дасгалд харгалзах товчний өнгө
export const lessonTipGenerator = (language, lessonid) => {
  let keyarray = [];
  if (language == "MON") {
    switch (lessonid) {
      case 10:
        keyarray = ["65", "83", "68", "70", "74", "75", "76", "186", "32"];
        break;
      case 20:
        keyarray = [
          "65",
          "83",
          "68",
          "70",

          "74",
          "75",
          "76",
          "186",

          "81",
          "87",
          "69",
          "82",
          "84",

          "85",
          "73",
          "79",
          "80",
          "219",

          "13",
          "32",
        ];
        break;
      case 30:
        keyarray = [
          "65",
          "83",
          "68",
          "70",

          "74",
          "75",
          "76",
          "186",

          "84",

          "219",

          "13",
          "32",

          "89",
          "71",
          "72",
          "66",
          "221",
          "222",
        ];
        break;
      case 40:
        keyarray = [
          "65",
          "83",
          "68",
          "70",

          "74",
          "75",
          "76",
          "186",

          "13",
          "32",

          "90",
          "88",
          "67",
          "86",
          "78",
          "77",
          "188",
          "190",
          "191",
        ];
        break;
      case 50:
        keyarray = [
          "65",
          "83",
          "68",
          "70",
          "71",
          "72",
          "74",
          "75",
          "76",
          "186",
          "222",
          "32",
          "81",
          "87",
          "69",
          "82",
          "84",
          "89",
          "85",
          "73",
          "79",
          "80",
          "219",
          "221",
          "90",
          "88",
          "67",
          "86",
          "66",
          "78",
          "77",
          "188",
          "190",
          "191",
          "189",
          "187",
        ];
        break;
      case 60:
        keyarray = [
          "65",
          "83",
          "68",
          "70",
          "74",
          "75",
          "76",
          "192",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "48",
          "189",
          "187",
          "46",
          "9",
          "220",
          "16",
          "1600",
          "186",
        ];
        break;
      default:
        keyarray = [
          "192",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "48",
          "189",
          "187",
          "46",
          "9",
          "81",
          "87",
          "69",
          "82",
          "84",
          "89",
          "85",
          "73",
          "79",
          "80",
          "219",
          "221",
          "220",
          "20",
          "65",
          "83",
          "68",
          "70",
          "71",
          "72",
          "74",
          "75",
          "76",
          "186",
          "222",
          "13",
          "16",
          "90",
          "88",
          "67",
          "86",
          "66",
          "78",
          "77",
          "188",
          "190",
          "181",
          "16",
          "17",
          "32",
          "191",
          "commandleft",
          "commandright",
          "optionright",
          "optionleft",
          "fn",
          "16",
          "1600",
        ];
    }
  } else {
    switch (lessonid) {
      case 10:
        keyarray = ["65", "83", "68", "70", "74", "75", "76", "32"];
        break;
      case 20:
        keyarray = [
          "65",
          "83",
          "68",
          "70",

          "74",
          "75",
          "76",

          "81",
          "87",
          "69",
          "82",
          "84",

          "85",
          "73",
          "79",
          "80",

          "13",
          "32",
        ];
        break;
      case 30:
        keyarray = [
          "65",
          "83",
          "68",
          "70",

          "74",
          "75",
          "76",

          "84",

          "13",
          "32",

          "89",
          "71",
          "72",
          "66",
        ];
        break;
      case 40:
        keyarray = [
          "65",
          "83",
          "68",
          "70",

          "74",
          "75",
          "76",

          "13",
          "32",

          "90",
          "88",
          "67",
          "86",
          "78",
          "77",
          "32",
        ];
        break;
      case 50:
        keyarray = [
          "65",
          "83",
          "68",
          "70",
          "71",
          "72",
          "74",
          "75",
          "76",
          "13",
          "32",
          "90",
          "88",
          "67",
          "86",
          "78",
          "77",
          "81",
          "87",
          "69",
          "82",
          "84",
          "89",
          "85",
          "73",
          "79",
          "80",
          "32",
        ];
        break;
      case 60:
        keyarray = [
          "65",
          "83",
          "68",
          "70",

          "74",
          "75",
          "76",
          "186",
          "222",
          "13",
          "16",
          "1600",
          "188",
          "190",
          "191",
          "219",
          "221",
          "220",
          "192",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "48",
          "189",
          "187",
          "46",
          "32",
        ];
        break;
      default:
        keyarray = [
          "192",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "48",
          "189",
          "187",
          "46",
          "9",
          "81",
          "87",
          "69",
          "82",
          "84",
          "89",
          "85",
          "73",
          "79",
          "80",
          "219",
          "221",
          "220",
          "20",
          "65",
          "83",
          "68",
          "70",
          "71",
          "72",
          "74",
          "75",
          "76",
          "186",
          "222",
          "13",
          "16",
          "90",
          "88",
          "67",
          "86",
          "66",
          "78",
          "77",
          "188",
          "190",
          "181",
          "16",
          "17",
          "32",
          "191",
          "commandleft",
          "commandright",
          "optionright",
          "optionleft",
          "fn",
          "16",
          "1600",
        ];
    }
  }
  return keyarray;
};

export const checkfinger = (language, key) => {
  let finger = [];
  checkfinger1(language, key) && finger.push("1");
  checkfinger2(language, key) && finger.push("2");
  checkfinger3(language, key) && finger.push("3");
  checkfinger4(language, key) && finger.push("4");
  checkfinger5(language, key) && finger.push("5");
  checkfinger6(language, key) && finger.push("6");
  checkfinger7(language, key) && finger.push("7");
  checkfinger8(language, key) && finger.push("8");
  checkfinger9(language, key) && finger.push("9");
  return finger;
};
// Тухай товчийг нэхэхэд хурууны өнгийг харуулахыг шийднэ.
export const checkfinger1 = (language, key) => {
  let finger = false;
  if (language == "MON") {
    if (
      [
        `=`,
        "+",
        "№",
        "1",
        "⇥",
        "ф",
        "Ф",
        "Й",
        "й",
        "я",
        "Я",
        // Шифт
        "7",
        "8",
        "9",
        "0",
        "Е",
        "Щ",
        "Н",
        "Г",
        "Ш",
        "Ү",
        "З",
        "К",
        "Ъ",
        "|",
        "Х",
        "Р",
        "О",
        "Л",
        "Д",
        "П",
        "И",
        "Т",
        "Ь",
        "В",
        "Ю",
      ].includes(key)
    )
      finger = true;
  }
  if (language == "ENG") {
    if (
      [
        "`",
        "~",
        "1",
        "!",
        "⇥",
        "q",
        "Q",
        "a",
        "A",
        "z",
        "Z",
        // SHift
        "^",
        "&",
        "*",
        "(",
        ")",
        "_",
        "+",
        "Y",
        "U",
        "I",
        "O",
        "P",
        "{",
        "}",
        "|",
        "H",
        "J",
        "K",
        "L",
        ":",
        ",",
        `"`,
        ",",
        "?",
        ">",
        "<",
        "M",
        "N",
      ].includes(key)
    )
      finger = true;
  }
  return finger;
};
export const checkfinger2 = (language, key) => {
  let finger = false;
  if (language == "MON") {
    if (["2", "-", "Ц", "ц", "ы", "Ы", "ч", "Ч"].includes(key)) finger = true;
  }
  if (language == "ENG") {
    if (["2", "@", "w", "W", "s", "S", "x", "X"].includes(key)) finger = true;
  }
  return finger;
};
export const checkfinger3 = (language, key) => {
  let finger = false;
  if (language == "MON") {
    if (["3", `"`, "у", "У", "б", "Б", "ё", "Ё"].includes(key)) finger = true;
  }
  if (language == "ENG") {
    if (["3", "#", "E", "e", "D", "d", "C", "c"].includes(key)) finger = true;
  }
  return finger;
};
export const checkfinger4 = (language, key) => {
  let finger = false;
  if (language == "MON") {
    if (
      [
        "₮",
        "ж",
        "ө",
        "с",
        ":",
        "э",
        "а",
        "м",
        "4",
        "Ж",
        "Ө",
        "С",
        "М",
        "А",
        "Э",
        "5",
      ].includes(key)
    )
      finger = true;
  }
  if (language == "ENG") {
    if (
      [
        "4",
        "$",
        "R",
        "r",
        "F",
        "f",
        "V",
        "v",
        "5",
        "%",
        "t",
        "T",
        "g",
        "G",
        "b",
        "B",
      ].includes(key)
    )
      finger = true;
  }
  return finger;
};
export const checkfinger5 = (language, key) => {
  let finger = false;
  if (language == "MON") {
    if (
      [
        "7",
        "_",
        "г",
        "Г",
        "р",
        "Р",
        "т",
        "Т",
        "И",
        "и",
        "Х",
        "х",
        "Н",
        "н",
        ".",
        "6",
      ].includes(key)
    )
      finger = true;
  }
  if (language == "ENG") {
    if (
      [
        "7",
        "&",
        "u",
        "U",
        "j",
        "J",
        "m",
        "M",
        "n",
        "N",
        "h",
        "H",
        "y",
        "Y",
        "6",
        "^",
      ].includes(key)
    )
      finger = true;
  }
  return finger;
};
export const checkfinger6 = (language, key) => {
  let finger = false;
  if (language == "MON") {
    if ([",", "ш", "о", "ь", "Ь", "О", "Ш", "8"].includes(key)) finger = true;
  }
  if (language == "ENG") {
    if (["8", "*", "i", "I", "k", "K", ",", "<"].includes(key)) finger = true;
  }
  return finger;
};
export const checkfinger7 = (language, key) => {
  let finger = false;
  if (language == "MON") {
    if (["%", "ү", "л", "в", "В", "Л", "Ү", "9"].includes(key)) finger = true;
  }
  if (language == "ENG") {
    if (["9", "(", "o", "O", "l", "L", ".", ">"].includes(key)) finger = true;
  }
  return finger;
};
export const checkfinger8 = (language, key) => {
  let finger = false;
  if (language == "MON") {
    if (
      [
        "?",
        "0",
        "е",
        "Е",
        "Щ",
        "щ",
        "⇠",
        "з",
        "З",
        "к",
        "К",
        "ъ",
        "Ъ",
        "|",
        "⏎",
        "ю",
        "Ю",
        "д",
        "Д",
        "п",
        "П",
        // Shift
        "М",
        "А",
        "Э",
        "5",
        "4",
        "Ж",
        "Ө",
        "С",
        "Ё",
        "Б",
        "У",
        "3",
        "2",
        "Ц",
        "Б",
        "Ё",
        "Ч",
        "Я",
        "Й",
        "Ф",
      ].includes(key)
    )
      finger = true;
  }
  if (language == "ENG") {
    if (
      [
        "0",
        ")",
        "-",
        "_",
        "=",
        "+",
        "p",
        "P",
        "[",
        "{",
        "]",
        "}",
        "|",
        ";",
        ":",
        "'",
        ",",
        `"`,
        "/",
        "?",
        "%",
        "T",
        "G",
        "B",
        "V",
        "F",
        "R",
        "$",
        "C",
        "D",
        "E",
        "#",
        "X",
        "S",
        "W",
        "@",
        "Z",
        "A",
        "Q",
        "!",
        "~",
        "⏎",
      ].includes(key)
    )
      finger = true;
  }
  return finger;
};
export const checkfinger9 = (language, key) => {
  let finger = false;
  if (language == "MON") {
    if ([" "].includes(key)) finger = true;
  }
  if (language == "ENG") {
    if ([" "].includes(key)) finger = true;
  }
  return finger;
};

const getMonKeyCodeArray = (key) => {
  let tip = [];
  switch (key) {
    case " ":
      tip = ["32"];
      break;
    //   35 үсэг дууслаа
    case "ф":
      tip = ["81"];
      break;
    case "ц":
      tip = ["87"];
      break;
    case "у":
      tip = ["69"];
      break;
    case "ж":
      tip = ["82"];
      break;
    case "э":
      tip = ["84"];
      break;
    case "н":
      tip = ["89"];
      break;
    case "г":
      tip = ["85"];
      break;
    case "ш":
      tip = ["73"];
      break;
    case "ү":
      tip = ["79"];
      break;
    case "з":
      tip = ["80"];
      break;
    case "к":
      tip = ["219"];
      break;
    case "ъ":
      tip = ["221"];
      break;
    case "й":
      tip = ["65"];
      break;
    case "ы":
      tip = ["83"];
      break;
    case "б":
      tip = ["68"];
      break;
    case "ө":
      tip = ["70"];
      break;
    case "а":
      tip = ["71"];
      break;
    case "х":
      tip = ["72"];
      break;
    case "р":
      tip = ["74"];
      break;
    case "о":
      tip = ["75"];
      break;
    case "л":
      tip = ["76"];
      break;
    case "д":
      tip = ["186"];
      break;
    case "п":
      tip = ["222"];
      break;
    case "я":
      tip = ["90"];
      break;
    case "ч":
      tip = ["88"];
      break;
    case "ё":
      tip = ["67"];
      break;
    case "с":
      tip = ["86"];
      break;
    case "м":
      tip = ["66"];
      break;
    case "и":
      tip = ["78"];
      break;
    case "т":
      tip = ["77"];
      break;
    case "ь":
      tip = ["188"];
      break;
    case "в":
      tip = ["190"];
      break;
    case "ю":
      tip = ["191"];
      break;
    case "Ф":
      tip = ["81", "1600"];
      break;
    case "Ц":
      tip = ["87", "1600"];
      break;
    case "У":
      tip = ["69", "1600"];
      break;
    case "Ж":
      tip = ["82", "1600"];
      break;
    case "Э":
      tip = ["84", "1600"];
      break;
    case "Н":
      tip = ["89", "16"];
      break;
    case "Г":
      tip = ["85", "16"];
      break;
    case "Ш":
      tip = ["73", "16"];
      break;
    case "Ү":
      tip = ["79", "16"];
      break;
    case "З":
      tip = ["80", "16"];
      break;
    case "К":
      tip = ["219", "16"];
      break;
    case "Ъ":
      tip = ["221", "16"];
      break;
    case "Й":
      tip = ["65", "1600"];
      break;
    case "Ы":
      tip = ["83", "1600"];
      break;
    case "Б":
      tip = ["68", "1600"];
      break;
    case "Ө":
      tip = ["70", "1600"];
      break;
    case "А":
      tip = ["71", "1600"];
      break;
    case "Х":
      tip = ["72", "16"];
      break;
    case "Р":
      tip = ["74", "16"];
      break;
    case "О":
      tip = ["75", "16"];
      break;
    case "Л":
      tip = ["76", "16"];
      break;
    case "Д":
      tip = ["186", "16"];
      break;
    case "П":
      tip = ["222", "16"];
      break;
    case "Я":
      tip = ["90", "1600"];
      break;
    case "Ч":
      tip = ["88", "1600"];
      break;
    case "Ё":
      tip = ["67", "1600"];
      break;
    case "С":
      tip = ["86", "1600"];
      break;
    case "М":
      tip = ["66", "1600"];
      break;
    case "И":
      tip = ["78", "16"];
      break;
    case "Т":
      tip = ["77", "16"];
      break;
    case "Ь":
      tip = ["188", "16"];
      break;
    case "В":
      tip = ["190", "16"];
      break;
    case "Ю":
      tip = ["191", "16"];
      break;
    case "е":
      tip = ["189"];
      break;
    case "Е":
      tip = ["189", "16"];
      break;
    case "щ":
      tip = ["187"];
      break;
    case "Щ":
      tip = ["187", "16"];
      break;

    // Too
    case "1":
      tip = ["49", "1600"];
      break;
    case "2":
      tip = ["50", "1600"];
      break;
    case "3":
      tip = ["51", "1600"];
      break;
    case "4":
      tip = ["52", "1600"];
      break;
    case "5":
      tip = ["53", "1600"];
      break;
    case "6":
      tip = ["54", "16"];
      break;
    case "7":
      tip = ["55", "16"];
      break;
    case "8":
      tip = ["56", "16"];
      break;
    case "9":
      tip = ["57", "16"];
      break;
    case "0":
      tip = ["48", "16"];
      break;
    //   Shiftгүй too

    case "№":
      tip = ["49"];
      break;
    case "-":
      tip = ["50"];
      break;
    case `"`:
      tip = ["51"];
      break;
    case "₮":
      tip = ["52"];
      break;
    case ":":
      tip = ["53"];
      break;
    case ".":
      tip = ["54"];
      break;
    case "_":
      tip = ["55"];
      break;
    case ",":
      tip = ["56"];
      break;
    case "%":
      tip = ["57"];
      break;
    case "?":
      tip = ["48"];
      break;
    // Үлдсэн товч
    case "=":
      tip = ["192"];
      break;
    case "+":
      tip = ["192", "1600"];
      break;
    case "⇠":
      tip = ["46"];
      break;
    case "⇥":
      tip = ["9"];
      break;
    case "⏎":
      tip = ["13"];
      break;
    case "|":
      tip = ["220", "16"];
      break;
  }
  return tip;
};

const getEngKeyCodeArray = (key) => {
  let tip = [];
  switch (key) {
    case " ":
      tip = ["32"];
      break;
    //   35 үсэг дууслаа
    case "q":
      tip = ["81"];
      break;
    case "w":
      tip = ["87"];
      break;
    case "e":
      tip = ["69"];
      break;
    case "r":
      tip = ["82"];
      break;
    case "t":
      tip = ["84"];
      break;
    case "y":
      tip = ["89"];
      break;
    case "u":
      tip = ["85"];
      break;
    case "i":
      tip = ["73"];
      break;
    case "o":
      tip = ["79"];
      break;
    case "p":
      tip = ["80"];
      break;
    case "[":
      tip = ["219"];
      break;
    case "]":
      tip = ["221"];
      break;
    case "a":
      tip = ["65"];
      break;
    case "s":
      tip = ["83"];
      break;
    case "d":
      tip = ["68"];
      break;
    case "f":
      tip = ["70"];
      break;
    case "g":
      tip = ["71"];
      break;
    case "h":
      tip = ["72"];
      break;
    case "j":
      tip = ["74"];
      break;
    case "k":
      tip = ["75"];
      break;
    case "l":
      tip = ["76"];
      break;
    case ";":
      tip = ["186"];
      break;
    case "'":
      tip = ["222"];
      break;
    case "z":
      tip = ["90"];
      break;
    case "x":
      tip = ["88"];
      break;
    case "c":
      tip = ["67"];
      break;
    case "v":
      tip = ["86"];
      break;
    case "b":
      tip = ["66"];
      break;
    case "n":
      tip = ["78"];
      break;
    case "m":
      tip = ["77"];
      break;
    case ",":
      tip = ["188"];
      break;
    case ".":
      tip = ["190"];
      break;
    case "/":
      tip = ["191"];
      break;
    case "Q":
      tip = ["81", "1600"];
      break;
    case "W":
      tip = ["87", "1600"];
      break;
    case "E":
      tip = ["69", "1600"];
      break;
    case "R":
      tip = ["82", "1600"];
      break;
    case "T":
      tip = ["84", "1600"];
      break;
    case "Y":
      tip = ["89", "16"];
      break;
    case "U":
      tip = ["85", "16"];
      break;
    case "I":
      tip = ["73", "16"];
      break;
    case "O":
      tip = ["79", "16"];
      break;
    case "P":
      tip = ["80", "16"];
      break;
    case "{":
      tip = ["219", "16"];
      break;
    case "}":
      tip = ["221", "16"];
      break;
    case "A":
      tip = ["65", "1600"];
      break;
    case "S":
      tip = ["83", "1600"];
      break;
    case "D":
      tip = ["68", "1600"];
      break;
    case "F":
      tip = ["70", "1600"];
      break;
    case "G":
      tip = ["71", "1600"];
      break;
    case "H":
      tip = ["72", "16"];
      break;
    case "J":
      tip = ["74", "16"];
      break;
    case "K":
      tip = ["75", "16"];
      break;
    case "L":
      tip = ["76", "16"];
      break;
    case `:`:
      tip = ["186", "16"];
      break;
    case `"`:
      tip = ["222", "16"];
      break;
    case "Z":
      tip = ["90", "1600"];
      break;
    case "X":
      tip = ["88", "1600"];
      break;
    case "C":
      tip = ["67", "1600"];
      break;
    case "V":
      tip = ["86", "1600"];
      break;
    case "B":
      tip = ["66", "1600"];
      break;
    case "N":
      tip = ["78", "16"];
      break;
    case "M":
      tip = ["77", "16"];
      break;
    case "<":
      tip = ["188", "16"];
      break;
    case ">":
      tip = ["190", "16"];
      break;
    case "?":
      tip = ["191", "16"];
      break;
    case "-":
      tip = ["189"];
      break;
    case "_":
      tip = ["189", "16"];
      break;
    case "=":
      tip = ["187"];
      break;
    case "+":
      tip = ["187", "16"];
      break;

    // Too
    case "!":
      tip = ["49", "1600"];
      break;
    case "@":
      tip = ["50", "1600"];
      break;
    case "#":
      tip = ["51", "1600"];
      break;
    case "$":
      tip = ["52", "1600"];
      break;
    case "%":
      tip = ["53", "1600"];
      break;
    case "^":
      tip = ["54", "16"];
      break;
    case "&":
      tip = ["55", "16"];
      break;
    case "*":
      tip = ["56", "16"];
      break;
    case "(":
      tip = ["57", "16"];
      break;
    case ")":
      tip = ["48", "16"];
      break;
    //   Shiftгүй too

    case "1":
      tip = ["49"];
      break;
    case "2":
      tip = ["50"];
      break;
    case `3`:
      tip = ["51"];
      break;
    case "4":
      tip = ["52"];
      break;
    case "5":
      tip = ["53"];
      break;
    case "6":
      tip = ["54"];
      break;
    case "7":
      tip = ["55"];
      break;
    case "8":
      tip = ["56"];
      break;
    case "9":
      tip = ["57"];
      break;
    case "0":
      tip = ["48"];
      break;
    // Үлдсэн товч
    case "`":
      tip = ["192"];
      break;
    case "~":
      tip = ["192", "1600"];
      break;
    case "⇠":
      tip = ["46"];
      break;
    case "⇥":
      tip = ["9"];
      break;
    case "⏎":
      tip = ["13"];
      break;
    case "|":
      tip = ["220", "16"];
      break;
  }
  return tip;
};
