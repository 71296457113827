import React, { useContext } from "react";
import { Menu } from "antd";

import { FontColorsOutlined, CaretDownOutlined } from "@ant-design/icons";
import FastContext from "../../context/FastContext";

export default function MyMenu() {
  const { SubMenu } = Menu;
  const ctxfast = useContext(FastContext);
  const handleClick = (e) => {
    switch (e.key) {
      case "a1":
        ctxfast.changeLangOrLevel(null, 10);
        break;
      case "a2":
        ctxfast.changeLangOrLevel(null, 20);
        break;
      case "a3":
        ctxfast.changeLangOrLevel(null, 30);
        break;
      case "a4":
        ctxfast.changeLangOrLevel(null, 40);
        break;
      case "a5":
        ctxfast.changeLangOrLevel(null, 50);
        break;
      case "a6":
        ctxfast.changeLangOrLevel(null, 60);
        break;
      case "b1":
        ctxfast.changeLangOrLevel(null, 310);
        break;
      case "b2":
        ctxfast.changeLangOrLevel(null, 320);
        break;
      case "b3":
        ctxfast.changeLangOrLevel(null, 330);
        break;
      case "b4":
        ctxfast.changeLangOrLevel(null, 340);
        break;
      case "l1":
        ctxfast.changeLangOrLevel("MON", null);
        break;
      case "l2":
        ctxfast.changeLangOrLevel("ENG", null);
        break;
      case "s1":
        ctxfast.openSettingModal();
        break;
      case "p1":
        ctxfast.changeStateShowPayment();
        break;
      case "p2":
        ctxfast.changeStateShowGuide();
        break;
      default:
        ctxfast.changeLangOrLevel(null, 70);
    }
  };

  return (
    
      <Menu
        defaultSelectedKeys={["l1", "lang"]}
        onClick={handleClick}
        mode="horizontal"
        theme="dark"
        style = {{textAlign:"center"}}
      >
        <SubMenu key="lang" icon={<FontColorsOutlined />} title="Хэл сонгох">
          <Menu.Item key="l1">Монгол хэлээр</Menu.Item>
          <Menu.Item key="l2">Англи хэлээр</Menu.Item>
        </SubMenu>

        <SubMenu
          key="first"
          icon={<CaretDownOutlined />}
          title="Үсэг, тоо, тэмдэгт"
        >
          <Menu.Item key="a1">Дасгал 1-Үндсэн товч</Menu.Item>
          <Menu.Item key="a2">Дасгал 2-Дээд товч</Menu.Item>
          <Menu.Item key="a3">Дасгал 3-Дунд товч</Menu.Item>
          <Menu.Item key="a4">Дасгал 4-Доод товч</Menu.Item>
          <Menu.Item key="a5">Дасгал 5-Бүх үсэг</Menu.Item>
          <Menu.Item key="a6">Дасгал 6-Тоо, тэмдэгт</Menu.Item>
        </SubMenu>

        <SubMenu key="second" icon={<CaretDownOutlined />} title="Үг, өгүүлбэр">
          <Menu.Item key="b1">3 үсэгтэй үг</Menu.Item>
          <Menu.Item key="b2">4 үсэгтэй үг</Menu.Item>
          <Menu.Item key="b3">5 үсэгтэй үг</Menu.Item>
          <Menu.Item key="b4">Өгүүлбэр</Menu.Item>
        </SubMenu>
        <Menu.Item key="s1">Тохиргоо</Menu.Item>
        <Menu.Item key="p1">Төлбөр</Menu.Item>
        <Menu.Item key="p2">Заавар</Menu.Item>
      </Menu>
   
  );
}
