import React, { useContext, useEffect } from "react";
import CustomerContext from "../../context/CustomerContext";
import {
  Layout,
  Row,
  Col,
  Space,
  Select,
  Input,
  Button,
  Menu,
  Dropdown,
} from "antd";
const { Header, Footer, Sider, Content } = Layout;
const { Option } = Select;
const { Search } = Input;
let listpage = [];
export default function CustomerPagination() {
  const ctxcustomer = useContext(CustomerContext);

  for (let i = 1; i <= ctxcustomer.state.pageCount; i++) {
    !listpage.includes(i) && listpage.push(i);
  }

  return (
    <div>
      <span>
        Хэрэглэгчид{" "}
        {ctxcustomer.state.total > 0 && ` (${ctxcustomer.state.total})`}
      </span>
      {ctxcustomer.state.pageCount > 1 && ctxcustomer.state.prevPage > 0 && (
        <Button
          type="ghost"
          size="small"
          onClick={() => ctxcustomer.changeState("selpage", 1)}
        >
          1
        </Button>
      )}
      {ctxcustomer.state.prevPage > 1 && (
        <Button
          type="ghost"
          size="small"
          onClick={() =>
            ctxcustomer.changeState("selpage", ctxcustomer.state.prevPage)
          }
        >
          {ctxcustomer.state.prevPage}
        </Button>
      )}
      {ctxcustomer.state.total > 0 && (
        <Button type="ghost" size="small">
          {ctxcustomer.state.start}-{ctxcustomer.state.end}
        </Button>
      )}
      {ctxcustomer.state.pageCount > ctxcustomer.state.nextPage && (
        <Button
          type="ghost"
          size="small"
          onClick={() =>
            ctxcustomer.changeState("selpage", ctxcustomer.state.nextPage)
          }
        >
          {ctxcustomer.state.nextPage}
        </Button>
      )}
      {ctxcustomer.state.pageCount >= ctxcustomer.state.nextPage &&
        ctxcustomer.state.nextPage > 0 && (
          <Button
            type="ghost"
            size="small"
            onClick={() =>
              ctxcustomer.changeState("selpage", ctxcustomer.state.pageCount)
            }
          >
            {ctxcustomer.state.pageCount}
          </Button>
        )}

      {ctxcustomer.state.pageCount > 3 && (
        <Select
          value={ctxcustomer.state.currPage}
          style={{ width: 53 }}
          size="small"
          onSelect={(value, event) => ctxcustomer.changeState("selpage", value)}
        >
          {listpage.map((el) => (
            <Option value={el}>{el}</Option>
          ))}
        </Select>
      )}
    </div>
  );
}
