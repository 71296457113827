import { useState, useEffect, useContext } from "react";
import FastContext from "../context/FastContext";
//1
const useKeyPress = (callback) => {
  const ctxfast = useContext(FastContext);
  //2
  const [keyPressed, setKeyPressed] = useState();
  //3
  useEffect(() => {
    //4
    const downHandler = ({ key }) => {
      ctxfast.handleKeyDown(key);
      if (keyPressed !== key && key.length === 1) {
        setKeyPressed(key);
        callback && callback(key);
      }
    };
    //5
    const upHandler = () => {
      setKeyPressed(null);
    };

    //6
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      //7
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });
  //8
  return keyPressed;
};

export default useKeyPress;
