import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import { FastStore } from "./context/FastContext";
import { UserStore } from "./context/UserContext";
import { CustomerStore } from "./context/CustomerContext";
import MainPage from "./page/MainPage";
import MetaTags from "react-meta-tags";
import AdminPage from "./page/AdminPage";
import NotFound from "./page/NotFound";
import myogimage from "./assets/image/fasttypemonwithhand.png";
import PrivacyPolice from "./page/PrivacyPolice";

function App() {
  sessionStorage.setItem("token", "Bearer 123456789");
  sessionStorage.setItem("activation", "z");
  return (
    <div className="App">
      <MetaTags>
        <title>
          10 хуруугаар хурдан бичих сурах программ || fast type mon ||
          fasttypemon.mn || fast typing mon || 10 huruugaar hurdan bichij surah
          programm
        </title>
        <meta
          name="description"
          content=" 10 хуруугаар хурдан бичих сурах программ || fast type mon ||
          fasttypemon.mn || fast typing mon || 10 huruugaar hurdan bichij surah programm "
        />
        <meta
          property="og:title"
          content=" 10 хуруугаар хурдан бичих сурах программ || fast type mon ||
          fasttypemon.mn || fast typing mon || 10 huruugaar hurdan bichij surah programm"
        />
        <meta property="og:image" content={myogimage} />
      </MetaTags>
      <FastStore>
        <UserStore>
          <CustomerStore>
            <Router basename={`${process.env.PUBLIC_URL}`}>
              <Switch>
                <Route exact path={`/`} component={MainPage} />
                <Route exact path={`/customer`} component={AdminPage} />
                <Route
                  exact
                  path={`/privacypolicy`}
                  component={PrivacyPolice}
                />
                <Route component={NotFound} />
              </Switch>
            </Router>
          </CustomerStore>
        </UserStore>
      </FastStore>
    </div>
  );
}

export default App;
