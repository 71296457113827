import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
export default function NotFound() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Уучлаарай. Таны хайсан хуудас олдсонгүй"
      extra={
        <Link to="/">
          <Button type="primary">Нүүр хуудасруу буцах</Button>
        </Link>
      }
    />
  );
}
