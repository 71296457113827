import React, { useEffect } from "react";
import { Modal, Button, Progress, Row, Col } from "antd";
import FastContext from "../../context/FastContext";
import css from "./style.module.css";
const prettyMilliseconds = require("pretty-ms");
export default function MyStatus() {
  const ctxfast = React.useContext(FastContext);

  return (
    <Modal
      bodyStyle={{ backgroundColor: "#ebebeb", color: "black" }}
      centered
      visible={ctxfast.state.showModalStatus}
      width={600}
      footer={[
        <Button
          title="Компьютерийн гарын ESC товч дарж хаах боломжтой"
          onClick={() => ctxfast.toReady(null, null)}
        >
          Хаах (Esc)
        </Button>,
      ]}
      onCancel={() => ctxfast.toReady(null, null)}
    >
      <br />
      <div>
        <Row align="center">
          <Col className={css.mysubtitle}>
            <div>www.FastTypeMon.mn</div>
          </Col>
        </Row>
        <Row>
          <Col flex="200px">Нийт дарсан товчны тоо</Col>
          <Col flex="auto" style={{ fontWeight: "bold" }}>
            {ctxfast.state.drillall}
          </Col>
        </Row>
        <Row>
          <Col flex="200px">Зөв дарсан товчны тоо</Col>
          <Col flex="auto" style={{ fontWeight: "bold" }}>
            {ctxfast.state.drillcorrect}
          </Col>
        </Row>
        <Row>
          <Col flex="200px">Буруу дарсан товчны тоо</Col>
          <Col flex="auto" style={{ fontWeight: "bold" }}>
            {ctxfast.state.drillincorrect}
          </Col>
        </Row>
        <Row>
          <Col flex="200px">Зөв дарсан товчны хувь</Col>
          <Col flex="auto" style={{ fontWeight: "bold" }}>
            {Math.floor(
              (ctxfast.state.drillcorrect * 100) /
                (ctxfast.state.drillall == 0 ? 1 : ctxfast.state.drillall)
            )}
            %
          </Col>
        </Row>
        <Row>
          <Col flex="200px">Хугацаа</Col>
          <Col flex="auto" style={{ fontWeight: "bold" }}>
            {prettyMilliseconds(ctxfast.state.usedtime)
              .replace("s", "сек")
              .replace("m", "мин")}
          </Col>
        </Row>
        <Row>
          <Col flex="200px">Амжилт</Col>
          <Col flex="auto" style={{ fontWeight: "bold" }}>
            {Math.floor(
              (ctxfast.state.drillcorrect * 60000) /
                (ctxfast.state.usedtime == 0
                  ? 100000000
                  : ctxfast.state.usedtime)
            )}{" "}
            товч/мин
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
