import React, { useContext, useState } from "react";
import "antd/dist/antd.css";
import {
  Layout,
  Row,
  Col,
  Space,
  Select,
  Input,
  Button,
  Menu,
  Dropdown,
} from "antd";
import CustomerContext from "../../context/CustomerContext";
import css from "./style.module.css";
export default function CustomerTable() {
  const ctxcustomer = useContext(CustomerContext);
  let mytable = (
    <div>
      <table style={{ width: "100%" }}>
        <tr>
          <td className={css.mytd} align="left">
            -
          </td>
          <td className={css.mytd} align="left">
            №
          </td>
          <td className={css.mytd} align="left">
            Зураг
          </td>
          <td className={css.mytd} align="left">
            Нэр
          </td>
          <td className={css.mytd} align="left">
            И-Мэйл
          </td>
          <td className={css.mytd} align="left">
            Утас
          </td>
          <td className={css.mytd} align="left">
            ФэйсбүүкID
          </td>
          <td className={css.mytd} align="left">
            Төлөв
          </td>
          <td className={css.mytd} align="left">
            Эхэлсэн
          </td>
          <td className={css.mytd} align="left">
            Дууссан
          </td>
          <td className={css.mytd} align="left">
            Дансны мэдээлэл
          </td>
        </tr>
        {ctxcustomer.state.listData.map((el) => (
          <tr>
            <td
              onClick={() => ctxcustomer.editCustomer(el.id)}
              style={{ color: "blue" }}
            >
              Идэвхжүүлэх
            </td>
            <td className={css.mytd} align="left">
              {el.id}
            </td>
            <td className={css.mytd} align="left">
              <img src={el.picture} />
            </td>
            <td className={css.mytd} align="left">
              {el.name}
            </td>
            <td className={css.mytd} align="left">
              {el.email}
            </td>
            <td className={css.mytd} align="left">
              {el.mobilephone}
            </td>
            <td className={css.mytd} align="left">
              {el.userID}
            </td>
            <td className={css.mytd} align="left">
              {el.active == 1 ? "Идэвхтэй" : "-"}
            </td>
            <td className={css.mytd} align="left">
              {el.dateactivated}
            </td>
            <td className={css.mytd} align="left">
              {el.dateexpired}
            </td>
            <td className={css.mytd} align="left">
              {el.dans}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );

  return (
    <div>
      <Row>
        <Col span={16}>{mytable}</Col>
        <Col span={8} style={{ background: "gray" }}></Col>
      </Row>
    </div>
  );
}
