import React, { useState } from "react";
import axios from "../Util/myAxios";
import { useHistory } from "react-router-dom";
const UserContext = React.createContext();
const initialState = {
  loadingRegister: false,
  loadingLogin: false,
  error: false,
  errorMessage: "",
  expireDate: "",
  token: sessionStorage.getItem("token") || "Bearer 123456789",
  email: sessionStorage.getItem("email") || null,
  name: sessionStorage.getItem("name") || null,
  picture: sessionStorage.getItem("picture") || null,

  userid: sessionStorage.getItem("userid") || null,
  // expireDate: null,
  filtername: sessionStorage.getItem("filtername") || null,
  angi_id: sessionStorage.getItem("angi_id") || null,
  role: sessionStorage.getItem("role") || null,
  username: sessionStorage.getItem("username") || null,
};

export const UserStore = (props) => {
  const history = useHistory();
  const [state, setState] = useState(initialState);
  const loginUser = (mobilephone, password) => {
    setState({ ...state, loadingLogin: true });
    const data = {
      mobilephone,
      password,
    };
    axios
      .post("login/login", data)
      .then((result) => {
        console.log(result);
        const token = result.data.token;
        const id = result.data.user.id;
        const name = result.data.user.name;
        const email = result.data.user.email;
        const picture = result.data.user.picture;
        const userID = result.data.user.userID;
        const mobilephone = result.data.user.mobilephone;

        sessionStorage.setItem("token", token);
        sessionStorage.setItem("id", id);
        sessionStorage.setItem("name", name);
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("picture", picture);
        sessionStorage.setItem("userID", userID);
        sessionStorage.setItem("mobilephone", mobilephone);
        setState((state) => ({
          ...state,
          loadingLogin: false,
          token,
          id,
          mobilephone,
          name,
          email,
          picture,
          userID,
        }));
      })
      .catch((err) => {
        setState({
          ...state,
          loadingLogin: false,
          error: true,
          errorMessage:
            err.response != undefined
              ? err.response.data.error.message
              : "Сервертэй холбогдож чадсангүй",
          // errorCode: err.code,
          token: null,
          id: null,
          expireDate: null,
        });
      });
  };

  const userFacebookLogin = (
    fbuserid,
    fbusername,
    fbuseremail,
    fbuserpicture
  ) => {
    setState({ ...state, loadingLogin: true });
    const data = {
      fbuserid,
      fbusername,
      fbuseremail,
      fbuserpicture,
    };
    axios
      .post("login/fblogin", data)
      .then((result) => {
        console.log(result);
        const token = result.data.token;
        const activation = result.data.activation;
        const id = result.data.user.id;
        const name = result.data.user.name;
        const email = result.data.user.email;
        const picture = result.data.user.picture;
        const userID = result.data.user.userID;
        const mobilephone = result.data.user.mobilephone;

        sessionStorage.setItem("token", token);
        sessionStorage.setItem("id", id);
        sessionStorage.setItem("name", name);
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("picture", picture);
        sessionStorage.setItem("userID", userID);
        sessionStorage.setItem("mobilephone", mobilephone);
        sessionStorage.setItem("activation", activation);
        setState((state) => ({
          ...state,
          loadingLogin: false,

          token,
          id,
          mobilephone,
          name,
          email,
          picture,
          userID,
        }));
      })
      .catch((err) => {
        setState({
          ...state,
          loadingLogin: false,
          loadingRegister: false,
          error: true,
          errorMessage:
            err.response != undefined
              ? err.response.data.error.message
              : "Сервертэй холбогдож чадсангүй",
          // errorCode: err.code,
          token: null,
          id: null,
          expireDate: null,
        });
      });
  };

  // AWS

  const userFacebookLoginAWS = (
    fbuserid,
    fbusername,
    fbuseremail,
    fbuserpicture
  ) => {
    setState({ ...state, loadingLogin: true });
    const data = {
      fbuserid,
      fbusername,
      fbuseremail,
      fbuserpicture,
    };
    axios
      .put(
        "https://140rw9iyof.execute-api.us-east-2.amazonaws.com/items",
        data,
        { headers: { authorization: "token" + fbuserid } }
      )
      .then((result) => {
        console.log(result);
        const token = result.data.profile.fbuserid;
        const activation = result.data.info.value2;
        const id = result.data.profile.fbuserid;
        const name = result.data.profile.fbusername;
        const email = result.data.profile.email;
        const picture = result.data.profile.fbuserpicture;
        const userID = result.data.profile.fbuserid;
        const mobilephone = result.data.profile.fbuserid;

        sessionStorage.setItem("token", token);
        sessionStorage.setItem("id", id);
        sessionStorage.setItem("name", name);
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("picture", picture);
        sessionStorage.setItem("userID", userID);
        sessionStorage.setItem("mobilephone", mobilephone);
        sessionStorage.setItem("activation", activation);
        setState((state) => ({
          ...state,
          loadingLogin: false,
          token,
          id,
          mobilephone,
          name,
          email,
          picture,
          userID,
        }));
      })
      .catch((err) => {
        setState({
          ...state,
          loadingLogin: false,
          loadingRegister: false,
          error: true,
          errorMessage:
            err.response != undefined
              ? err.response.data.error.message
              : "Сервертэй холбогдож чадсангүй",
          // errorCode: err.code,
          token: null,
          id: null,
          expireDate: null,
        });
      });
  };

  const logout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("activation");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("picture");
    axios
      .post("login/logout")
      .then((result) => console.log(result))
      .catch((err) => console.log(err));
    window.location.reload();
  };

  const registerUser = (name, mobilephone, password) => {
    setState((state) => ({ ...state, loadingRegister: true }));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    let formData = new FormData();
    formData.append("name", name);
    formData.append("mobilephone", mobilephone);
    formData.append("password", password);

    axios
      .post("login/register", formData, config)
      .then((result) => {
        console.log(result);
        const token = result.data.token;
        const id = result.data.user.id;
        const name = result.data.user.name;
        const email = result.data.user.email;
        const picture = result.data.user.picture;
        const userID = result.data.user.userID;
        const mobilephone = result.data.user.mobilephone;

        sessionStorage.setItem("token", token);
        sessionStorage.setItem("id", id);
        sessionStorage.setItem("name", name);
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("picture", picture);
        sessionStorage.setItem("userID", userID);
        sessionStorage.setItem("mobilephone", mobilephone);

        setState((state) => ({
          ...state,
          loadingRegister: false,
          token,
          id,
          mobilephone,
          name,
          email,
          picture,
          userID,
        }));
      })
      .catch((error) => {
        setState({
          ...state,
          loadingRegister: false,
          error: true,
          errorMessage: "Алдаа гарлаа:",
          success: false,
          successMessage: null,
        });
      });
  };
  return (
    <UserContext.Provider
      value={{
        state,
        loginUser,
        logout,
        registerUser,
        userFacebookLogin,
        userFacebookLoginAWS,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
