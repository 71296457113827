import React, { useState } from "react";
import axios from "../Util/myAxios";
import { useHistory } from "react-router-dom";
const CustomerContext = React.createContext();
const initialState = {
  error: false,
  errorMessage: "",
  loadingData: false,
  listData: [],

  filtervalue: "",
  selpage: 1,
  limit: 50,
  total: 0,
  pageCount: 0,
  start: 0,
  end: 0,
  nextPage: 0,
  prevPage: 0,
  currPage: 0,
  showEdit: false,
  selCustomerID: null,
};

export const CustomerStore = (props) => {
  const [state, setState] = useState(initialState);
  const loadLast50data = () => {
    setState((state) => ({ ...state, loadingData: true }));
    axios
      .get("customer?")
      .then((response) => {
        const listData = response.data.data.rows;
        setState((state) => ({
          ...state,
          listData,
          error: false,
          total: response.data.data.count,
          loadingData: false,
          pageCount: response.data.pagination.pageCount,
          start: response.data.pagination.start,
          end: response.data.pagination.end,
          limit: response.data.pagination.limit,
          nextPage: response.data.pagination.nextPage,
          prevPage: response.data.pagination.prevPage,
          currPage: response.data.pagination.currPage,
        }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loadingData: true,
          error: true,
          errorMessage: "Өгөгдлийг дуудахад алдаа гарлаа",
        }));
      });
  };
  const changeState = (name, value) => {
    setState((state) => ({ ...state, [name]: value }));
  };
  const filterCustomer = () => {
    setState((state) => ({
      ...state,
      loadingData: true,
      error: false,
    }));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    axios
      .get(
        `/customer?page=${state.selpage}&limit=${state.limit}&filtervalue=${state.filtervalue}`,
        config
      )
      .then((response) => {
        const listData = response.data.data.rows;
        setState((state) => ({
          ...state,
          listData,
          error: false,
          total: response.data.data.count,
          loadingData: false,
          pageCount: response.data.pagination.pageCount,
          start: response.data.pagination.start,
          end: response.data.pagination.end,
          limit: response.data.pagination.limit,
          nextPage: response.data.pagination.nextPage,
          prevPage: response.data.pagination.prevPage,
          currPage: response.data.pagination.currPage,
        }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          error: true,
          errorMessage: "Өгөгдлийн дуудахад алдаа гарлаа",
          loadingData: false,
        }));
      });
  };
  const editCustomer = (id) => {
    setState((state) => ({ ...state, showEdit: true, selCustomerID: id }));
  };
  const closeEditModal = () => {
    setState((state) => ({ ...state, showEdit: false }));
  };
  const saveCustomerPay = (
    id,
    active,
    mobilephone,
    dans,
    dateactivated,
    dateexpired
  ) => {
    let formData = new FormData();
    formData.append("active", active ? 1 : 0);
    formData.append("mobilephone", mobilephone);
    formData.append("dans", dans);
    formData.append("dateactivated", dateactivated);
    formData.append("dateexpired", dateexpired);
    setState((state) => ({
      ...state,
      loadingData: true,
      error: false,
    }));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    axios
      .put(`customer/${id}`, formData, config)
      .then((response) => {
        alert(
          "Амжилттай" +
            response.data.data.name +
            ", " +
            response.data.data.email +
            "-ын мэдээллийг заслаа"
        );
        setState((state) => ({
          ...state,
          showEdit: false,
          success: true,
          error: false,
        }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          error: true,
          errorMessage: "алдаа гарлаа",
          loadingData: false,
        }));
      });
  };
  return (
    <CustomerContext.Provider
      value={{
        state,
        loadLast50data,
        filterCustomer,
        changeState,
        editCustomer,
        closeEditModal,
        saveCustomerPay,
      }}
    >
      {props.children}
    </CustomerContext.Provider>
  );
};

export default CustomerContext;
