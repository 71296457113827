import React, { useContext, useState } from "react";
import FastContext from "../../../context/FastContext";
import css from "./style.module.css";
export default function Hand() {
  const ctxfast = useContext(FastContext);
  return (
    <div>
      <div className={css.hand}>
        {ctxfast.state.showword != 2 ? (
          <div>
            <div
              style={{
                backgroundColor: ctxfast.state.showfingercolor && "orange",
              }}
              className={
                ctxfast.state.tipfinger.includes("1")
                  ? css.finger1
                  : css.fingerstop1
              }
            />
            <div
              style={{
                backgroundColor: ctxfast.state.showfingercolor && "yellow",
              }}
              className={
                ctxfast.state.tipfinger.includes("2")
                  ? css.finger2
                  : css.fingerstop2
              }
            />
            <div
              style={{
                backgroundColor: ctxfast.state.showfingercolor && "hotpink",
              }}
              className={
                ctxfast.state.tipfinger.includes("3")
                  ? css.finger3
                  : css.fingerstop3
              }
            />
            <div
              className={
                ctxfast.state.tipfinger.includes("4")
                  ? css.finger4
                  : css.fingerstop4
              }
              style={{
                backgroundColor: ctxfast.state.showfingercolor && "#66ffff",
              }}
            />
            <div
              className={
                ctxfast.state.tipfinger.includes("5")
                  ? css.finger5
                  : css.fingerstop5
              }
              style={{
                backgroundColor: ctxfast.state.showfingercolor && "#b3ff66",
              }}
            />
            <div
              className={
                ctxfast.state.tipfinger.includes("6")
                  ? css.finger6
                  : css.fingerstop6
              }
              style={{
                backgroundColor: ctxfast.state.showfingercolor && "hotpink",
              }}
            />
            <div
              style={{
                backgroundColor: ctxfast.state.showfingercolor && "yellow",
              }}
              className={
                ctxfast.state.tipfinger.includes("7")
                  ? css.finger7
                  : css.fingerstop7
              }
            />
            <div
              className={
                ctxfast.state.tipfinger.includes("8")
                  ? css.finger8
                  : css.fingerstop8
              }
              style={{
                backgroundColor: ctxfast.state.showfingercolor && "orange",
              }}
            />
            <div
              className={
                ctxfast.state.tipfinger.includes("9")
                  ? css.thumb9
                  : css.thumbstop9
              }
              style={{
                backgroundColor: ctxfast.state.showfingercolor && "#E9DEDA",
              }}
            />
            <div
              className={
                ctxfast.state.tipfinger.includes("9")
                  ? css.thumb10
                  : css.thumbstop10
              }
              style={{
                backgroundColor: ctxfast.state.showfingercolor && "#E9DEDA",
              }}
            />
          </div>
        ) : (
          <div>
            <div className={css.fingerstop1} />
            <div className={css.fingerstop2} />
            <div className={css.fingerstop3} />
            <div className={css.fingerstop4} />
            <div className={css.fingerstop5} />
            <div className={css.fingerstop6} />
            <div className={css.fingerstop7} />
            <div className={css.fingerstop8} />
            <div className={css.thumbstop9} />
            <div className={css.thumbstop10} />
          </div>
        )}
      </div>
    </div>
  );
}
