import React from "react";
import { Row, Col } from "antd";
export default function Contact(props) {
  return (
    <div>
      <table style={{ width: "100%", color: "white" }}>
        <tr>
          <td
            colSpan="100"
            style={{
              backgroundColor: "#001529",
              boxShadow: "0px 3px 0px 0px #143147",
            }}
          >
            Холбоо барих
          </td>
        </tr>
        <tr style={{ backgroundColor: "#1E354A" }}>
          <td align="right">Утасны дугаар:&nbsp;</td>
          <td align="left">{props.phone}</td>
        </tr>
        <tr style={{ backgroundColor: "#1E354A" }}>
          <td align="right">Фэйсбүүк хаяг:&nbsp;</td>
          <td align="left">
            <a href="http://www.facebook.com/fasttypemon">
              www.facebook.com/fasttypemon
            </a>
          </td>
        </tr>
      </table>
    </div>
  );
}
