import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Avatar, Badge, Dropdown, Menu, Button, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import UserContext from "../../context/UserContext";

export default function UserProfile() {
  const { Title } = Typography;
  const ctxuser = useContext(UserContext);

  const menu = (
    <Menu>
      <Menu.Item>
        <span onClick={() => ctxuser.logout()}>Гарах</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement="bottomRight" arrow>
      <span>
        <span className="avatar-item">
          <Badge count={0}>
            <Avatar shape="square" icon={<img src={ctxuser.state.picture} />} />
          </Badge>
        </span>
        &nbsp;
        <span style={{ color: "black" }}>{ctxuser.state.name}</span>
      </span>
    </Dropdown>
  );
}
