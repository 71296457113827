import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import FacebookLogin from "react-facebook-login";
import {
  Menu,
  Dropdown,
  Layout,
  Progress,
  Breadcrumb,
  Tooltip,
  Row,
  Col,
  Button,
  message,
  Spin,
  Typography,
} from "antd";
import { SmileOutlined } from "@ant-design/icons";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import MyMenu from "../components/Menu/MyMenu";
import useKeyPress from "../components/useKeyPress";
import MyKeyBoard from "../components/Keyboard/MyKeyBoard";
import MyText from "../components/MyText/MyText";
import useCountDown from "react-countdown-hook";
import FastContext from "../context/FastContext";
import MyStatus from "../components/Status/MyStatus";
import UserProfile from "../components/Menu/UserProfile";
import SettingModal from "../components/Modal/SettingModal";
import Tulbur from "../components/Tulbur/Tulbur";
import UserContext from "../context/UserContext";
import css from "./style.module.css";
import UserGuide from "../components/UserGuide/UserGuide";
import MyBanner from "../components/MyBanner";
const { Title } = Typography;
const md5 = require("md5");
const SubMenu = Menu.SubMenu;
const { Header, Footer, Sider, Content } = Layout;
const prettyMilliseconds = require("pretty-ms");
const initialTime = 60 * 1000; // initial time in milliseconds, defaults to 60000
const interval = 100; // interval to change remaining time amount, defaults to 1000
const { Paragraph } = Typography;
function MainPage() {
  // console.log("MAIN PAGE RENDER HIIGDCHIHLEE");
  const ctxfast = useContext(FastContext);
  const ctxuser = useContext(UserContext);
  const [timeLeft, { start, pause, resume, reset }] = useCountDown(
    initialTime,
    interval
  );
  const [accessToken, setAccessToken] = useState("");
  const componentClicked = (data) => {
    console.log("data", data);
  };

  const responseFacebook = (response) => {
    setAccessToken(response.accessToken);
    if (response.status == "unknown") {
      console.log("Cancel hiisen baina shuu dee");
    } else {
      const fbuserID = response.userID;
      const fbusername = response.name;
      const fbuseremail = response.email;
      const fbuserpicture =
        (response.picture &&
          response.picture.data &&
          response.picture.data.url) ||
        "noavatar.png";
      ctxuser.userFacebookLoginAWS(
        fbuserID,
        fbusername,
        fbuseremail,
        fbuserpicture
      );
    }
  };

  useKeyPress((key) => {
    // console.log(key); //Зогссон бөгөөд үр дүнгийн мод хаагдсан уу гэдгийг шалгаж байна
    if (
      ctxfast.state.ready &&
      !ctxfast.state.started &&
      !ctxfast.state.showModalStatus &&
      !ctxfast.state.stoptimer
      // &&(" " == key || "Enter" == key)
    ) {
      //тийм бол шинээр эхлүүлье
      handleStart();
    }
  });
  useEffect(() => {
    sessionStorage.setItem("token", "Bearer 123456789");
    if (!ctxfast.state.started) {
      reset();
      return;
    }
    if (timeLeft > 0) {
      if (ctxfast.state.randomtext.length < ctxfast.state.elementiindugaar) {
        ctxfast.stopWriting(timeLeft);
        reset();
      }
    } else if (ctxfast.state.started) {
      ctxfast.stopWriting(timeLeft);
      reset();
    }
  }, [timeLeft]);

  const handleStart = () => {
    sessionStorage.setItem("token", "Bearer 123456789");
    // if (!sessionStorage.getItem("token")) {
    //   message.info("Та нэвтрэнэ үү!");
    //    return;
    // }

    if (
      ctxfast.state.lessonid == 10 ||
      sessionStorage.getItem("activation") === "z"
      //md5(sessionStorage.getItem("email")).concat("dfca")
    ) {
      if (ctxfast.state.randomtext == "") {
        message.error("Хэл, дасгал сонгоно уу");
      }
      reset();
      if (
        ctxfast.state.ready &&
        !ctxfast.state.started &&
        !ctxfast.state.showModalStatus &&
        timeLeft == 0
      ) {
        start(ctxfast.state.hugatsaa);
        ctxfast.startWriting();
        console.log("Эхлүүллээ");
      }
    } else {
      ctxfast.changeState("showpayment", true);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("token", "Bearer 123456789");
    if (!isNaN(sessionStorage.getItem("hugatsaa"))) {
      ctxfast.changeState(
        "hugatsaa",
        parseInt(sessionStorage.getItem("hugatsaa")) || 40000
      );
    }
  }, []);

  return (
    <div className="App">
      <div>
        <Row>
          <Col flex="auto"></Col>
          <Col
            flex="640px"
            style={{
              textAlign: "left",
              justifyContent: "center",
              fontWeight: "900",
            }}
          >
            <Title level={4} style={{ color: "#3B5998" }}>
              10 хуруугаар хурдан бичиж сурах вэб сайт
            </Title>
          </Col>
          <Col flex="200px" style={{ padding: "5px" }}>
            <Spin spinning={ctxuser.state.loadingLogin}>
              {sessionStorage.getItem("token") ? (
                <UserProfile />
              ) : (
                <FacebookLogin
                  textButton="Фэйсбүүкээр нэвтрэх"
                  appId="740429100206249" //      1645089222338342     //тест-471105474282735   jinhene=740429100206249
                  autoLoad={false}
                  size="small"
                  fields="name,email,picture"
                  onClick={componentClicked}
                  callback={responseFacebook}
                  cssClass={css.myfacebookbuttonclass}
                />                
              )}
            </Spin>
          </Col>
          <Col flex="auto"></Col>
        </Row>
      </div>

      <Layout style={{ textAlign: "center" }}>
        <MyMenu />
        <Content className="site-layout">
          {!ctxfast.state.showpayment ? (
            <span>
              <Breadcrumb style={{ margin: "5px 0" }}>
                <Breadcrumb.Item>
                  {ctxfast.state.keyboardlang == "MON"
                    ? "Монгол хэлээр"
                    : "Англи хэлээр"}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {ctxfast.state.lessonid < 299
                    ? "Үсэг, тоо, тэмдэгт"
                    : "Үг, Өгүүлбэр"}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {ctxfast.state.lessonid == 10 && "Үндсэн товч"}
                  {ctxfast.state.lessonid == 20 && "Дээд товч"}
                  {ctxfast.state.lessonid == 30 && "Дунд товч"}
                  {ctxfast.state.lessonid == 40 && "Доод товч"}
                  {ctxfast.state.lessonid == 50 && "Тоо, тэмдэг"}
                  {ctxfast.state.lessonid == 60 && "Бүх шатны товч"}
                  {ctxfast.state.lessonid == 310 && "3 үсэгтэй үг"}
                  {ctxfast.state.lessonid == 320 && "4 үсэгтэй үг"}
                  {ctxfast.state.lessonid == 330 && "5 үсэгтэй үг"}
                  {ctxfast.state.lessonid == 340 && "Өгүүлбэр"}
                </Breadcrumb.Item>
              </Breadcrumb>
              <div
                className="site-layout-background"
                style={{ padding: 0, minHeight: 380 }}
              >
                <input
                  style={{ display: "none" }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
                <div align="center">
                  <Row style={{ width: "840px" }}>
                    <Col flex="130px" style={{ textAlign: "left" }}>
                      <div> Даралт: {ctxfast.state.drillall}</div>
                    </Col>
                    <Col flex="auto">
                      <Tooltip title="Улаан өнгөөр алдаж дарсан товчны хувь, Ногоон өнгөөр зөв дарсан товчний хувь гарна">
                        <Progress
                          style={{ width: "100%" }}
                          percent={100}
                          success={{
                            percent:
                              ctxfast.state.drillall == 0
                                ? 100
                                : Math.floor(ctxfast.state.drillcorrect * 100) /
                                  ctxfast.state.drillall,
                            strokeColor: "green",
                          }}
                          strokeColor="red"
                          showInfo={false}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row style={{ width: "840px" }}>
                    <Col flex="130px" style={{ textAlign: "left" }}>
                      <div>
                        Хугацаа:{" "}
                        {prettyMilliseconds(ctxfast.state.hugatsaa)
                          .replace("s", "сек")
                          .replace("m", "мин")}{" "}
                      </div>
                    </Col>
                    <Col flex="auto" align="left">
                      <Progress
                        title="Хугацааг хувиар илэрхийлж харуулна"
                        percent={
                          100 - (timeLeft * 100) / ctxfast.state.hugatsaa
                        }
                        showInfo={false}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                </div>
                <MyText />

                <MyKeyBoard handleStart={handleStart} />
                <MyStatus />
                <SettingModal />
              </div>
            </span>
          ) : ctxfast.state.showguide ? (
            <span>
              <UserGuide />
            </span>
          ) : (
            <Tulbur />
          )}
        </Content>
      </Layout>
      <Footer
        style={{
          // borderTop: "1px solid #e8e8e8",
          // position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
          // backgroundColor: "white",
          textAlign: "center",
          height: "30px",
          margin: "0px",
          padding: "0px",
        }}
      >
        <br />
        Лавлах утас: 99886117,{" "}
        <a href="https://fasttypemon.mn/privacypolicy.html">
          <span style={{ color: "black" }}>
            Нууцлалын бодлого - Privacy Policy
          </span>
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a href="https://fasttypemon.mn/terms.html">
          <span style={{ color: "black" }}>Terms and Conditions</span>
        </a>
      </Footer>
    </div>
  );
}

export default MainPage;
