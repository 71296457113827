import React, { useContext, useState } from "react";
import {
  textGenerator,
  tipgenerator,
  lessonTipGenerator,
  checkfinger,
} from "../Util/MyGenerator";
import { keyReplacer } from "../Util/MyKeyReplacer";
import useSound from "use-sound";
import soundbeep from "../assets/sound/beep-1.ogg";
import soundclick from "../assets/sound/click-1.ogg";
import { notification } from "antd";
const FastContext = React.createContext();
const initialState = {
  showpayment: false,
  showguide: false,
  casesensitive: false,
  ready: false,
  showfingercolor: true,
  showword: 3, //1 harna 2 harahgui 3 zuvhun hereglehee harna
  stoptimer: false,
  usedtime: 0,
  randomtext: "",
  started: false,
  correctkey: 0,
  incorrectkey: 0,
  totalkey: 0,
  elementiindugaar: 1,
  start: "",
  current: "",
  end: "",
  showModalStatus: false,
  showModalSetting: false,
  tipbutton: [
    "192",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "48",
    "189",
    "187",
    "46",
    "9",
    "81",
    "87",
    "69",
    "82",
    "84",
    "89",
    "85",
    "73",
    "79",
    "80",
    "219",
    "221",
    "220",
    "20",
    "65",
    "83",
    "68",
    "70",
    "71",
    "72",
    "74",
    "75",
    "76",
    "186",
    "222",
    "13",
    "16",
    "90",
    "88",
    "67",
    "86",
    "66",
    "78",
    "77",
    "188",
    "190",
    "181",
    "16",
    "17",
    "32",
    "191",
    "commandleft",
    "commandright",
    "optionright",
    "optionleft",
    "fn",
    "16",
    "1600",
  ],
  usebutton: [],
  errbutton: [],
  tipfinger: [],
  //
  // Үр дүн тооцох
  drillall: 0,
  drillcorrect: 0,
  drillincorrect: 0,
  lastdrillkey: "",
  //Тохиргооны хэсэг

  hugatsaa: 40000,
  showallkey: true,
  showusekey: false,
  playsound: true,
  // Сонгогдсон төлөв
  keyboardlang: "MON",
  lessonid: 10,
  //хурууны хөдөлгөөн харуулах

  tiplist1: [
    "192",
    "49",
    "9",
    "81",
    "20",
    "65",
    "16",
    "65",
    "fn",
    "17",
    "optionleft",
  ],
};

export const FastStore = (props) => {
  const [playError, { stop }] = useSound(soundbeep);
  const [playOk, { stop1 }] = useSound(soundclick);
  const [state, setState] = useState(initialState);
  const changeState = (name, value) => {
    setState((state) => ({ ...state, [name]: value }));
  };
  const changeStateShowPayment = () => {
    setState((state) => ({ ...state, showpayment: true, showguide: false }));
  };
  const changeStateShowGuide = () => {
    setState((state) => ({ ...state, showpayment: true, showguide: true }));
  };

  const stopWriting = (timeleft) => {
    console.log("ЗОГСООХ ҮЕИЙ ХУГАЦАА ҮЛДСЭН", timeleft);
    const usedtime = state.hugatsaa - timeleft;
    setState((state) => ({
      ...state,
      started: false,
      usedtime,
      showModalStatus: true,
    }));
  };
  const stopWritingFast = (timeleft) => {
    console.log("ЗОГСООХ ҮЕИЙ ХУГАЦАА ҮЛДСЭН", timeleft);
    const usedtime = state.hugatsaa - timeleft;
    setState((state) => ({
      ...state,
      started: false,
      usedtime,
      showModalStatus: true,
    }));
  };
  const handleKeyDown = (key1) => {
    let key = key1;
    const checkword = state.randomtext.substr(state.elementiindugaar - 1, 1);

    if (
      (key1.length == 1 && state.lessonid < 51 && key1 != " ") ||
      (key1.length == 1 &&
        state.lessonid > 309 &&
        state.lessonid < 331 &&
        key1 != " ")
    ) {
      key = keyReplacer(state.keyboardlang, key1);
    }

    if (
      key == checkword ||
      (key == "Tab" && checkword == "⇥") ||
      (key == "Enter" && checkword == "⏎") ||
      (key == "Backspace" && checkword == "⇠")
    ) {
      // Товчийн зөв дарсан тул нийт болон зөв дарсан товчны тоог өсгөе
      // Нөхцөл биеллээ
      if (state.started) {
        state.playsound && playOk();
        const newword = state.randomtext.substr(state.elementiindugaar, 1);

        const tipfinger = checkfinger(state.keyboardlang, newword);
        const tipbutton = tipgenerator(state.keyboardlang, newword);
        const writedtext =
          checkword == ` `
            ? state.start.concat(` `)
            : state.start.concat(checkword);
        const endtext = state.randomtext.substr(state.elementiindugaar + 1);

        setState((state) => ({
          ...state,
          elementiindugaar: state.elementiindugaar + 1,
          drillall: state.drillall + 1,
          drillcorrect: state.drillcorrect + 1,
          start: writedtext,
          current: newword,
          end: endtext,
          tipbutton,
          tipfinger,
          errbutton: [],
        }));
      }
    } else {
      // Дарсан товч шалгах товчтой таарахгүй бол

      if (key != "Shift" && key != "Alt" && key != "Meta") {
        if (state.started) {
          console.log(key);
          state.playsound && playError();
          if (state.lessonid == "60" || state.lessonid == "340") {
            notification.error({
              // message: `${key}-алдаж дарсан товч`,
              description: key == " " ? `Space` : key,
              placement: "bottomRigth",
              duration: 1,
              style: { width: "170px" },
              icon: null,
            });
          }
          setState((state) => ({
            ...state,
            errbutton: tipgenerator(state.keyboardlang, key),
            drillall: state.drillall + 1,
            drillincorrect: state.drillincorrect + 1,
          }));
        }
      }
    }
  };

  const startWriting = () => {
    if (state.ready) {
      const texturt = state.randomtext.length;
      const bichihuseg = state.randomtext.substr(0, 1);
      const uldsenuseg = state.randomtext.substr(1);
      const tipfinger = checkfinger(state.keyboardlang, bichihuseg);
      const tipbutton = tipgenerator(state.keyboardlang, bichihuseg);
      console.log("БИЧИХ ТЕКСТИЙН ЭХЛЭЛ", bichihuseg);
      setState((state) => ({
        ...state,
        showModalStatus: false,
        started: true,
        elementiindugaar: 1,
        current: bichihuseg,
        end: uldsenuseg,
        showfingercolor: false,
        stoptimer: false,
        tipfinger,
        tipbutton,
      }));
    }
  };

  const changeLangOrLevel = (lang, level) => {
    let lessonid = state.lessonid;
    let lessonlang = state.keyboardlang;

    if (lang != null) {
      lessonlang = lang;
    }
    if (level != null) {
      lessonid = level;
    }
    if (lessonid == "60" || lessonid == "340") {
      sessionStorage.getItem("token") &&
        notification.info({
          description: `Компьютерийн гарын хэл ${lessonlang} болгоно уу.`,
          placement: "bottomRigth",
          duration: 3,

          icon: null,
        });
    }
    setState((state) => ({
      ...state,
      forcestopwrite: true,
      showpayment: false,
    }));
    toReady(lessonlang, lessonid);
  };

  const toReady = (lang, level) => {
    let lessonid = state.lessonid;
    let lessonlang = state.keyboardlang;

    if (lang != null) {
      lessonlang = lang;
    }
    if (level != null) {
      lessonid = level;
    }

    // Текст бэлдэнэ:
    const bichihtext = textGenerator(lessonlang, lessonid, state.casesensitive);
    // const bichihtext = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

    // Сонгогдсон дасгалыг шалган keyboard-д хуруунуудыг харуулна
    const tipbutton = lessonTipGenerator(state.keyboardlang, lessonid); //dasgald hargalzah huruunuud gereltene.
    // const tipfinger = checkfinger(state.keyboardlang, bi)
    //Stated хадгалах
    setState((state) => ({
      ...state,
      started: false,

      keyboardlang: lessonlang,
      lessonid,
      stoptimer: false,
      elementiindugaar: 1,
      tipbutton,
      usebutton: tipbutton,
      randomtext: bichihtext,
      start: "",
      current: " ",
      end: bichihtext,
      showModalStatus: false,
      //хуруунууд Өнгө харуулна
      showfingercolor: true,
      //хурууны даралтын тоог тэглэе
      drillall: 0,
      drillcorrect: 0,
      drillincorrect: 0,
      lastdrillkey: "",
      usedtime: 0,
      ready: true,
      //хуруунуудын хөдөлгөөн зогсоон
      tipfinger: [],
    }));
  };
  const closeSettingModal = () => {
    setState((state) => ({ ...state, showModalSetting: false }));
  };
  const openSettingModal = () => {
    setState((state) => ({
      ...state,
      showModalSetting: true,
      showpayment: false,
    }));
  };
  return (
    <FastContext.Provider
      value={{
        state,
        changeState,
        handleKeyDown,
        stopWriting,
        startWriting,
        toReady,
        changeLangOrLevel,
        openSettingModal,
        closeSettingModal,
        changeStateShowGuide,
        changeStateShowPayment,
      }}
    >
      {props.children}
    </FastContext.Provider>
  );
};
export default FastContext;
