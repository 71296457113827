import React, { useContext, useState } from "react";
import {
  Checkbox,
  Switch,
  Slider,
  InputNumber,
  Input,
  Row,
  Col,
  Radio,
  Button,
  Form,
  DatePicker,
  Space,
} from "antd";
import CustomerContext from "../../context/CustomerContext";
const { TextArea } = Input;
const dateFormat = "YYYY/MM/DD";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const initialState = {
  dans: "",
  mobilephone: "",
  active: false,
  dateactivated: null,
  dateexpired: null,
};
export default function CustomerEditForm() {
  const [state, setState] = useState(initialState);
  const ctxcustomer = useContext(CustomerContext);

  return (
    <Form {...layout} name="basic" initialValues={{ remember: true }}>
      <Form.Item label="  Эрх идэвхжүүлэх">
        <Switch
          defaultChecked={false}
          onChange={(checked) => {
            setState((state) => ({ ...state, active: checked }));
            console.log("hahah", checked);
          }}
        />
      </Form.Item>
      <Form.Item label="  Утас, нэр">
        <Input
          allowClear
          onChange={(event) => {
            setState((state) => ({
              ...state,
              mobilephone: event.target.value,
            }));
          }}
        />
      </Form.Item>
      <Form.Item label="    Дансны мэдээлэл">
        <TextArea
          placeholder="Дансны дугаар, банк, гүйлгээний талаархи мэдээллэл"
          allowClear
          onChange={(event) => {
            setState((state) => ({ ...state, dans: event.target.value }));
          }}
        />
      </Form.Item>
      <Form.Item label="Эхлэх огноо">
        <DatePicker
          allowClear
          format={dateFormat}
          onChange={(date, dateString) =>
            setState((state) => ({ ...state, dateactivated: dateString }))
          }
        />
      </Form.Item>
      <Form.Item label="Дуусах огноо">
        <DatePicker
          allowClear
          format={dateFormat}
          onChange={(date, dateString) =>
            setState((state) => ({ ...state, dateexpired: dateString }))
          }
        />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
          onClick={() =>
            ctxcustomer.saveCustomerPay(
              ctxcustomer.state.selCustomerID,
              state.active,
              state.mobilephone,
              state.dans,
              state.dateactivated,
              state.dateexpired
            )
          }
        >
          Хадгалах
        </Button>
      </Form.Item>
    </Form>
  );
}
