import React, { useContext, useState } from "react";
import { Modal, Button, Progress, Row, Col } from "antd";

import CustomerContext from "../../context/CustomerContext";
import CustomerEditForm from "./CustomerEditForm";

export default function CustomerModal() {
  const ctxcustomer = useContext(CustomerContext);
  return (
    <Modal
      style={{ backgroundColor: "yellow", paddingBottom: "0" }}
      bodyStyle={{ backgroundColor: "white", color: "black" }}
      centered
      visible={ctxcustomer.state.showEdit}
      width={600}
      footer={[
        <Button onClick={() => ctxcustomer.closeEditModal()}>Хаах</Button>,
      ]}
      onCancel={() => ctxcustomer.closeEditModal()}
    >
      <CustomerEditForm />
    </Modal>
  );
}
