import React, { useEffect, useState } from "react";
import { Card, Col, Row, Typography } from "antd";
import "./index.css";
import MessengerCustomerChat from "react-messenger-customer-chat";
import Contact from "../Contact/Contact";
import Tabletop from "tabletop";
const { Paragraph } = Typography;

export default function Tulbur() {
  const [data, setData] = useState([
    {
      ownerdans: "5000816828",
      ownerner: "Нарангэрэл",
      ownerphone: "99886117",
      name: "Программ ашиглах төлбөр:",
      price: "50000",
      pricelow: "20000",
      month: "3 сар",
      bonustext: "",
    },
  ]);

  useEffect(() => {
    Tabletop.init({
      key: "1VtrFRWSJtDZJYFGImtwCV_GTxBVhTNsu9tv-i-WqE4o",
      simpleSheet: true,
    })
      .then((data) => {
        setData(data);
        console.log(data);
      })
      .catch((err) => console.warn(err));
  }, []);

  return (
    <div>
      <h1>Төлбөр төлөх</h1>
      <MessengerCustomerChat pageId="753577438049822" appId="740429100206249" />
      <Row>
        <Col span={12} align="right">
          {data && data[0] && data[0].name}
        </Col>
        <Col span={12} align="left">
          <b>
            &nbsp;<s> {data && data[0] && data[0].price}₮</s>
            {"  "}
            {data && data[0] && data[0].pricelow}₮
          </b>
        </Col>
      </Row>
      <Row>
        <Col span={12} align="right">
          Хугацаа:
        </Col>
        <Col span={12} align="left">
          <b>&nbsp; {data && data[0] && data[0].month}</b>
        </Col>
      </Row>
      <Row>
        <Col span={24} align="center" style={{ color: "red" }}>
          {data && data[0] && data[0].bonustext}
        </Col>
      </Row>
      <hr></hr>
      <Row align="center">
        <Col> Хаан банкны&nbsp;</Col>
        <Col>
          <b>
            <Paragraph copyable>
              {data && data[0] && data[0].ownerdans}
            </Paragraph>
          </b>
        </Col>
        &nbsp;тоот&nbsp;
        <Col>
          <Paragraph copyable>
            <b> {data && data[0] && data[0].ownerner}</b>
          </Paragraph>
        </Col>
        <Col>
          &nbsp;дансанд гүйлгээний утгад <b>Өөрийн нэр болон утасны дугаар</b>
          &nbsp; бичиж шилжүүлнэ. Жишээ нь: Д. Пүрэвсүрэн 99886117
        </Col>
      </Row>
      <hr />
      Та өөрийн төлбөрөө төлсөн бол фэйсбүүк хуудасруу гүйлгээний утга,
      бүртгэлтэй и-мэйл хаягаа бичиж эрхээ нээлгээрэй. <br />
      <div style={{ width: "100%", paddingTop: "100px" }}>
        <Contact phone={data && data[0] && data[0].ownerphone} />
      </div>
    </div>
  );
}
