import React from "react";

export default function PrivacyPolice() {
  return (
    <div align="left ">
      <h1>Privacy Policy</h1>
      <div>
        <div>
          <div>
            <h1>Privacy Policy</h1>
            <p>Last updated: April 22, 2021</p>
            <p>
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
            </p>
            <p>
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy.{" "}
            </p>
            <h1>Interpretation and Definitions</h1>
            <h2>Interpretation</h2>
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            <h2>Definitions</h2>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
              <li>
                <p>
                  <strong>Account</strong>&nbsp;means a unique account created
                  for You to access our Service or parts of our Service.
                </p>
              </li>
              <li>
                <p>
                  <strong>Company</strong>&nbsp;(referred to as either "the
                  Company", "We", "Us" or "Our" in this Agreement) refers to
                  Fasttypemon.
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies</strong>&nbsp;are small files that are placed
                  on Your computer, mobile device or any other device by a
                  website, containing the details of Your browsing history on
                  that website among its many uses.
                </p>
              </li>
              <li>
                <p>
                  <strong>Country</strong>&nbsp;refers to: Mongolia
                </p>
              </li>
              <li>
                <p>
                  <strong>Device</strong>&nbsp;means any device that can access
                  the Service such as a computer, a cellphone or a digital
                  tablet.
                </p>
              </li>
              <li>
                <p>
                  <strong>Personal Data</strong>&nbsp;is any information that
                  relates to an identified or identifiable individual.
                </p>
              </li>
              <li>
                <p>
                  <strong>Service</strong>&nbsp;refers to the Website.
                </p>
              </li>
              <li>
                <p>
                  <strong>Service Provider</strong>&nbsp;means any natural or
                  legal person who processes the data on behalf of the Company.
                  It refers to third-party companies or individuals employed by
                  the Company to facilitate the Service, to provide the Service
                  on behalf of the Company, to perform services related to the
                  Service or to assist the Company in analyzing how the Service
                  is used.
                </p>
              </li>
              <li>
                <p>
                  <strong>Third-party Social Media Service</strong>
                  &nbsp;refers to any website or any social network website
                  through which a User can log in or create an account to use
                  the Service.
                </p>
              </li>
              <li>
                <p>
                  <strong>Usage Data</strong>&nbsp;refers to data collected
                  automatically, either generated by the use of the Service or
                  from the Service infrastructure itself (for example, the
                  duration of a page visit).
                </p>
              </li>
              <li>
                <p>
                  <strong>Website</strong>&nbsp;refers to Fasttypemon,
                  accessible from&nbsp;
                  <a
                    href="https://www.fasttypemon.mn/"
                    target="_blank"
                    rel="external nofollow noopener"
                  >
                    https://www.fasttypemon.mn
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <strong>You</strong>&nbsp;means the individual accessing or
                  using the Service, or the company, or other legal entity on
                  behalf of which such individual is accessing or using the
                  Service, as applicable.
                </p>
              </li>
            </ul>
            <h1>Collecting and Using Your Personal Data</h1>
            <h2>Types of Data Collected</h2>
            <h3>Personal Data</h3>
            <p>
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </p>
            <ul>
              <li>
                <p>Email address</p>
              </li>
              <li>
                <p>First name and last name</p>
              </li>
              <li>
                <p>Phone number</p>
              </li>
              <li>
                <p>Address, State, Province, ZIP/Postal code, City</p>
              </li>
              <li>
                <p>Usage Data</p>
              </li>
            </ul>
            <h3>Usage Data</h3>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
            </p>
            <p>
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </p>
            <p>
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>
            <h3>Information from Third-Party Social Media Services</h3>
            <p>
              The Company allows You to create an account and log in to use the
              Service through the following Third-party Social Media Services:
            </p>
            <ul>
              <li>Google</li>
              <li>Facebook</li>
              <li>Twitter</li>
            </ul>
            <p>
              If You decide to register through or otherwise grant us access to
              a Third-Party Social Media Service, We may collect Personal data
              that is already associated with Your Third-Party Social Media
              Service's account, such as Your name, Your email address, Your
              activities or Your contact list associated with that account.
            </p>
            <p>
              You may also have the option of sharing additional information
              with the Company through Your Third-Party Social Media Service's
              account. If You choose to provide such information and Personal
              Data, during registration or otherwise, You are giving the Company
              permission to use, share, and store it in a manner consistent with
              this Privacy Policy.
            </p>
            <h3>Tracking Technologies and Cookies</h3>
            <p>
              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:
            </p>
            <ul>
              <li>
                <strong>Cookies or Browser Cookies.</strong>&nbsp;A cookie is a
                small file placed on Your Device. You can instruct Your browser
                to refuse all Cookies or to indicate when a Cookie is being
                sent. However, if You do not accept Cookies, You may not be able
                to use some parts of our Service. Unless you have adjusted Your
                browser setting so that it will refuse Cookies, our Service may
                use Cookies.
              </li>
              <li>
                <strong>Flash Cookies.</strong>&nbsp;Certain features of our
                Service may use local stored objects (or Flash Cookies) to
                collect and store information about Your preferences or Your
                activity on our Service. Flash Cookies are not managed by the
                same browser settings as those used for Browser Cookies. For
                more information on how You can delete Flash Cookies, please
                read "Where can I change the settings for disabling, or deleting
                local shared objects?" available at&nbsp;
                <a
                  href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                  target="_blank"
                  rel="external nofollow noopener"
                >
                  https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                </a>
              </li>
              <li>
                <strong>Web Beacons.</strong>&nbsp;Certain sections of our
                Service and our emails may contain small electronic files known
                as web beacons (also referred to as clear gifs, pixel tags, and
                single-pixel gifs) that permit the Company, for example, to
                count users who have visited those pages or opened an email and
                for other related website statistics (for example, recording the
                popularity of a certain section and verifying system and server
                integrity).
              </li>
            </ul>
            <p>
              Cookies can be "Persistent" or "Session" Cookies. Persistent
              Cookies remain on Your personal computer or mobile device when You
              go offline, while Session Cookies are deleted as soon as You close
              Your web browser. Learn more about cookies:&nbsp;
              <a
                href="https://www.freeprivacypolicy.com/blog/cookies/"
                target="_blank"
                rel="noopener"
              >
                Cookies: What Do They Do?
              </a>
              .
            </p>
            <p>
              We use both Session and Persistent Cookies for the purposes set
              out below:
            </p>
            <ul>
              <li>
                <p>
                  <strong>Necessary / Essential Cookies</strong>
                </p>
                <p>Type: Session Cookies</p>
                <p>Administered by: Us</p>
                <p>
                  Purpose: These Cookies are essential to provide You with
                  services available through the Website and to enable You to
                  use some of its features. They help to authenticate users and
                  prevent fraudulent use of user accounts. Without these
                  Cookies, the services that You have asked for cannot be
                  provided, and We only use these Cookies to provide You with
                  those services.
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies Policy / Notice Acceptance Cookies</strong>
                </p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>
                  Purpose: These Cookies identify if users have accepted the use
                  of cookies on the Website.
                </p>
              </li>
              <li>
                <p>
                  <strong>Functionality Cookies</strong>
                </p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>
                  Purpose: These Cookies allow us to remember choices You make
                  when You use the Website, such as remembering your login
                  details or language preference. The purpose of these Cookies
                  is to provide You with a more personal experience and to avoid
                  You having to re-enter your preferences every time You use the
                  Website.
                </p>
              </li>
            </ul>
            <p>
              For more information about the cookies we use and your choices
              regarding cookies, please visit our Cookies Policy or the Cookies
              section of our Privacy Policy.
            </p>
            <h2>Use of Your Personal Data</h2>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
              <li>
                <p>
                  <strong>To provide and maintain our Service</strong>,
                  including to monitor the usage of our Service.
                </p>
              </li>
              <li>
                <p>
                  <strong>To manage Your Account:</strong>&nbsp;to manage Your
                  registration as a user of the Service. The Personal Data You
                  provide can give You access to different functionalities of
                  the Service that are available to You as a registered user.
                </p>
              </li>
              <li>
                <p>
                  <strong>For the performance of a contract:</strong>&nbsp;the
                  development, compliance and undertaking of the purchase
                  contract for the products, items or services You have
                  purchased or of any other contract with Us through the
                  Service.
                </p>
              </li>
              <li>
                <p>
                  <strong>To contact You:</strong>&nbsp;To contact You by email,
                  telephone calls, SMS, or other equivalent forms of electronic
                  communication, such as a mobile application's push
                  notifications regarding updates or informative communications
                  related to the functionalities, products or contracted
                  services, including the security updates, when necessary or
                  reasonable for their implementation.
                </p>
              </li>
              <li>
                <p>
                  <strong>To provide You</strong>&nbsp;with news, special offers
                  and general information about other goods, services and events
                  which we offer that are similar to those that you have already
                  purchased or enquired about unless You have opted not to
                  receive such information.
                </p>
              </li>
              <li>
                <p>
                  <strong>To manage Your requests:</strong>&nbsp;To attend and
                  manage Your requests to Us.
                </p>
              </li>
              <li>
                <p>
                  <strong>For business transfers:</strong>&nbsp;We may use Your
                  information to evaluate or conduct a merger, divestiture,
                  restructuring, reorganization, dissolution, or other sale or
                  transfer of some or all of Our assets, whether as a going
                  concern or as part of bankruptcy, liquidation, or similar
                  proceeding, in which Personal Data held by Us about our
                  Service users is among the assets transferred.
                </p>
              </li>
              <li>
                <p>
                  <strong>For other purposes</strong>: We may use Your
                  information for other purposes, such as data analysis,
                  identifying usage trends, determining the effectiveness of our
                  promotional campaigns and to evaluate and improve our Service,
                  products, services, marketing and your experience.
                </p>
              </li>
            </ul>
            <p>
              We may share Your personal information in the following
              situations:
            </p>
            <ul>
              <li>
                <strong>With Service Providers:</strong>&nbsp;We may share Your
                personal information with Service Providers to monitor and
                analyze the use of our Service, to contact You.
              </li>
              <li>
                <strong>For business transfers:</strong>&nbsp;We may share or
                transfer Your personal information in connection with, or during
                negotiations of, any merger, sale of Company assets, financing,
                or acquisition of all or a portion of Our business to another
                company.
              </li>
              <li>
                <strong>With Affiliates:</strong>&nbsp;We may share Your
                information with Our affiliates, in which case we will require
                those affiliates to honor this Privacy Policy. Affiliates
                include Our parent company and any other subsidiaries, joint
                venture partners or other companies that We control or that are
                under common control with Us.
              </li>
              <li>
                <strong>With business partners:</strong>&nbsp;We may share Your
                information with Our business partners to offer You certain
                products, services or promotions.
              </li>
              <li>
                <strong>With other users:</strong>&nbsp;when You share personal
                information or otherwise interact in the public areas with other
                users, such information may be viewed by all users and may be
                publicly distributed outside. If You interact with other users
                or register through a Third-Party Social Media Service, Your
                contacts on the Third-Party Social Media Service may see Your
                name, profile, pictures and description of Your activity.
                Similarly, other users will be able to view descriptions of Your
                activity, communicate with You and view Your profile.
              </li>
              <li>
                <strong>With Your consent</strong>: We may disclose Your
                personal information for any other purpose with Your consent.
              </li>
            </ul>
            <h2>Retention of Your Personal Data</h2>
            <p>
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>
            <p>
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>
            <h2>Transfer of Your Personal Data</h2>
            <p>
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </p>
            <p>
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </p>
            <p>
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>
            <h2>Disclosure of Your Personal Data</h2>
            <h3>Business Transactions</h3>
            <p>
              If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </p>
            <h3>Law enforcement</h3>
            <p>
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>
            <h3>Other legal requirements</h3>
            <p>
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </p>
            <ul>
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of the Company</li>
              <li>
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li>
                Protect the personal safety of Users of the Service or the
                public
              </li>
              <li>Protect against legal liability</li>
            </ul>
            <h2>Security of Your Personal Data</h2>
            <p>
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>
            <h1>Children's Privacy</h1>
            <p>
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.
            </p>
            <p>
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent's consent before We collect and use that
              information.
            </p>
            <h1>Links to Other Websites</h1>
            <p>
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
            <h1>Changes to this Privacy Policy</h1>
            <p>
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </p>
            <p>
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <h1>Contact Us</h1>
            <p>
              If you have any questions about this Privacy Policy, You can
              contact us:
            </p>
            <ul>
              <li>
                <p>By email:&nbsp;naran.wot@gmail.com</p>
              </li>
              <li>
                <p>By phone number: +976-99886117</p>
              </li>
              <li>
                <p>
                  Facebook Page:{" "}
                  <a href="https://facebook.com/fasttypemon">
                    https://facebook.com/fasttypemon
                  </a>
                  <a />
                </p>
                <a></a>
              </li>
              <a></a>
            </ul>
            <a></a>
          </div>
          <a>
            <div id="out_en" className="agreement_output">
              &nbsp;
            </div>
          </a>
        </div>
        <a></a>

        <a>
          <footer>
            <div className="container">Privacy Policy for Fasttypemon</div>
          </footer>
          {/* #######  YAY, I AM THE SOURCE EDITOR! #########*/}
        </a>
        <section>
          <a></a>
          <div className="en section_container active">
            <a></a>
            <div id="md_en" className="agreement_md" data-target="out_en">
              <a></a>
              <section>
                <a></a>
                <div className="en section_container active">
                  <a></a>
                  <div id="md_en" className="agreement_md" data-target="out_en">
                    <a>
                      <h1>
                        <span>Нууцлалын бодлого</span>
                      </h1>
                      <p>
                        <span>Сүүлд шинэчлэгдсэн: 2021 оны 4-р сарын 22</span>
                      </p>
                      <p>
                        <span>
                          Энэхүү Нууцлалын Бодлого нь таныг уг үйлчилгээг
                          ашиглах үед таны мэдээллийг цуглуулах, ашиглах,
                          задруулах талаар баримтлах бодлого, журмыг тодорхойлж,
                          нууцлалын эрх, хууль таныг хэрхэн хамгаалдаг талаар
                          танд хэлдэг.
                        </span>
                      </p>
                      <p>
                        <span>
                          Бид таны хувийн мэдээллийг үйлчилгээ үзүүлэх,
                          сайжруулах зорилгоор ашигладаг.&nbsp;Үйлчилгээг
                          ашигласнаар та энэхүү Нууцлалын бодлогын дагуу
                          мэдээлэл цуглуулах, ашиглахыг зөвшөөрч байна.
                        </span>
                      </p>
                      <h1>
                        <span>Тайлбар ба тодорхойлолт</span>
                      </h1>
                      <h2>
                        <span>Тайлбар</span>
                      </h2>
                      <p>
                        <span>
                          Эхний үсгийг томоор бичсэн үгс нь дараахь нөхцөлд
                          тодорхойлогдсон утгатай байна.&nbsp;Дараахь
                          тодорхойлолтууд нь олон тоогоор илэрхийлэгдэхээс үл
                          хамааран ижил утгатай байна.
                        </span>
                      </p>
                      <h2>
                        <span>Тодорхойлолт</span>
                      </h2>
                      <p>
                        <span>Энэхүү Нууцлалын Бодлогын зорилгоор:</span>
                      </p>
                    </a>
                    <ul>
                      <a>
                        <li>
                          <p>
                            <strong>
                              <span>Данс</span>
                            </strong>
                            <span>
                              &nbsp;&nbsp;гэдэг нь манай үйлчилгээнд эсвэл манай
                              үйлчилгээний зарим хэсэгт нэвтрэх боломжийг танд
                              зориулж бүтээсэн өвөрмөц дансыг хэлнэ.
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              <span>Компани</span>
                            </strong>
                            <span>
                              &nbsp;&nbsp;(энэхүү гэрээнд "Компани", "Бид",
                              "Бид" эсвэл "Манай" гэсэн аль нэгийг хэлнэ) нь
                              Fasttypemon-ийг хэлнэ.
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              <span>Күүки</span>
                            </strong>
                            <span>
                              &nbsp;&nbsp;нь таны компьютер, мобайл төхөөрөмж
                              эсвэл бусад төхөөрөмж дээр вэбсайт дээр
                              байрлуулсан, таны вэбсайт дээрх хайлтын түүхийн
                              дэлгэрэнгүй мэдээллийг агуулсан жижиг файлууд юм.
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              <span>Улс</span>
                            </strong>
                            <span>&nbsp;&nbsp;нь Монголыг хэлнэ</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              <span>Төхөөрөмж</span>
                            </strong>
                            <span>
                              &nbsp;&nbsp;гэдэг нь үйлчилгээнд нэвтрэх боломжтой
                              компьютер, гар утас, дижитал таблет гэх мэт аливаа
                              төхөөрөмжийг хэлнэ.
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              <span>Хувийн мэдээлэл</span>
                            </strong>
                            <span>
                              &nbsp;&nbsp;гэдэг нь танигдсан эсвэл таних
                              боломжтой хувь хүнтэй холбоотой аливаа мэдээллийг
                              хэлнэ.
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              <span>Үйлчилгээ</span>
                            </strong>
                            <span>&nbsp;&nbsp;нь вэбсайтыг хэлнэ.</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              <span>Үйлчилгээ үзүүлэгч</span>
                            </strong>
                            <span>
                              &nbsp;&nbsp;гэдэг нь Компанийн өмнөөс өгөгдлийг
                              боловсруулдаг аливаа хуулийн этгээд юм.&nbsp;Энэ
                              нь Үйлчилгээг хөнгөвчлөх, Компанийн нэрийн өмнөөс
                              үйлчилгээ үзүүлэх, Үйлчилгээтэй холбоотой
                              үйлчилгээ үзүүлэх, эсвэл Үйлчилгээг хэрхэн ашиглаж
                              байгааг шинжлэхэд туслалцаа үзүүлэх зорилгоор
                              Компанид ажилдаг гуравдагч этгээдийн компаниуд
                              эсвэл хувь хүмүүсийг хэлнэ.
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              <span>
                                Гуравдагч этгээдийн сошиал медиа үйлчилгээ
                              </span>
                            </strong>
                            <span>
                              &nbsp;&nbsp;нь аливаа вэбсайт эсвэл нийгмийн
                              сүлжээний аливаа вэбсайтыг хэрэглэгчид нэвтрэх
                              буюу данс үүсгэх замаар үйлчилгээгээ ашиглахыг
                              хэлнэ.
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>
                              <span>Хэрэглээний өгөгдөл</span>
                            </strong>
                            <span>
                              &nbsp;&nbsp;гэдэг нь Үйлчилгээний ашиглалт эсвэл
                              Үйлчилгээний дэд бүтцийн өөрөө бий болгосон
                              (жишээлбэл, хуудсанд зочлох хугацаа) автоматаар
                              цуглуулсан&nbsp;<strong>өгөгдлийг</strong>
                              &nbsp;хэлнэ.
                            </span>
                          </p>
                        </li>
                      </a>
                      <li>
                        <a>
                          <p>
                            <strong>
                              <span>Вэбсайт</span>
                            </strong>
                            <span>
                              &nbsp;&nbsp;нь&nbsp;
                              <a
                                href="https://www.fasttypemon.mn/"
                                target="_blank"
                                rel="external nofollow noopener"
                              >
                                https://www.fasttypemon.mn
                              </a>
                              &nbsp;сайтаас нэвтрэх боломжтой Fasttypemon-ийг
                              хэлнэ&nbsp;
                            </span>
                          </p>
                        </a>
                      </li>
                      <a>
                        <li>
                          <p>
                            <strong>
                              <span>Та</span>
                            </strong>
                            <span>
                              &nbsp;&nbsp;үйлчилгээнд хамрагдах буюу ашиглаж буй
                              хувь хүн, эсвэл тухайн этгээд тухайн үйлчилгээнд
                              хамрагдаж байгаа компани эсвэл түүний өмнөөс бусад
                              хуулийн этгээдийг холбогдох тохиолдолд ашиглахыг
                              хэлнэ.
                            </span>
                          </p>
                        </li>
                      </a>
                    </ul>
                    <a>
                      <h1>
                        <span>Таны хувийн мэдээллийг цуглуулах, ашиглах</span>
                      </h1>
                      <h2>
                        <span>Цуглуулсан мэдээллийн төрөл</span>
                      </h2>
                      <h3>
                        <span>Хувийн мэдээлэл</span>
                      </h3>
                      <p>
                        <span>
                          Манай үйлчилгээг ашиглаж байхдаа бид тантай холбоо
                          барих эсвэл танихад ашиглаж болох хувийн тодорхой
                          мэдээллийг өгөхийг танаас хүсч болно.&nbsp;Хувийн
                          таних мэдээлэлд дараахь зүйлс орно, гэхдээ үүгээр
                          хязгаарлагдахгүй.
                        </span>
                      </p>
                      <ul>
                        <li>
                          <p>
                            <span>Имэйл хаяг</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>Овог, нэр</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>Утасны дугаар</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>
                              Хаяг, муж, муж, шуудан / шуудангийн код, хот
                            </span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>Хэрэглээний өгөгдөл</span>
                          </p>
                        </li>
                      </ul>
                      <h3>
                        <span>Хэрэглээний өгөгдөл</span>
                      </h3>
                      <p>
                        <span>
                          Үйлчилгээг ашиглахдаа ашиглалтын өгөгдлийг автоматаар
                          цуглуулдаг.
                        </span>
                      </p>
                      <p>
                        <span>
                          Ашиглалтын өгөгдөлд таны төхөөрөмжийн интернет
                          протоколын хаяг (жишээлбэл IP хаяг), хөтчийн төрөл,
                          хөтөчийн хувилбар, манай үйлчилгээний хуудсууд, таны
                          зочлох цаг, огноо, тэдгээр хуудсанд зарцуулсан цаг
                          хугацаа, өвөрмөц төхөөрөмж зэрэг мэдээлэл орно. танигч
                          ба бусад оношлогооны өгөгдөл.
                        </span>
                      </p>
                      <p>
                        <span>
                          Та мобайл төхөөрөмжөөр дамжуулан эсвэл үйлчилгээгээрээ
                          дамжуулан нэвтрэхдээ бид таны ашигладаг хөдөлгөөнт
                          төхөөрөмжийн төрөл, мобайл төхөөрөмжийн өвөрмөц ID,
                          таны мобайл төхөөрөмжийн IP хаяг, таны мобайл гэх мэт
                          тодорхой мэдээллийг автоматаар цуглуулж болно.
                          үйлдлийн систем, таны ашигладаг хөдөлгөөнт интернет
                          хөтөчийн төрөл, төхөөрөмжийн өвөрмөц танигч болон
                          бусад оношлогооны өгөгдөл.
                        </span>
                      </p>
                      <p>
                        <span>
                          Таныг манай үйлчилгээнд зочлох эсвэл мобайл
                          төхөөрөмжөөр эсвэл үйлчилгээнд нэвтрэх үед таны
                          хөтөчөөс илгээдэг мэдээллийг бид бас цуглуулж болно.
                        </span>
                      </p>
                      <h3>
                        <span>
                          Гуравдагч этгээдийн нийгмийн мэдээллийн
                          үйлчилгээнүүдийн мэдээлэл
                        </span>
                      </h3>
                      <p>
                        <span>
                          Компани нь дараахь Гуравдагч этгээдийн Нийгмийн Медиа
                          Үйлчилгээгээр дамжуулан данс үүсгэх, нэвтрэх боломжийг
                          танд олгоно.
                        </span>
                      </p>
                      <ul>
                        <li>
                          <span>Google</span>
                        </li>
                        <li>
                          <span>Facebook</span>
                        </li>
                        <li>
                          <span>Twitter</span>
                        </li>
                      </ul>
                      <p>
                        <span>
                          Хэрэв та Гуравдагч этгээдийн нийгмийн мэдээллийн
                          үйлчилгээгээр дамжуулан бүртгүүлэх эсвэл өөр хэлбэрээр
                          нэвтрэх эрх олгохоор шийдсэн бол бид таны нэр, имэйл
                          хаяг, таны үйл ажиллагаа гэх мэт таны гуравдагч
                          этгээдийн нийгмийн мэдээллийн үйлчилгээний данстай аль
                          хэдийн холбогдсон хувийн мэдээллийг цуглуулах
                          боломжтой. эсвэл тухайн данстай холбоотой таны холбоо
                          барих жагсаалт.
                        </span>
                      </p>
                      <p>
                        <span>
                          Та нэмэлт мэдээллийг өөрийн гуравдагч этгээдийн
                          нийгмийн мэдээллийн үйлчилгээний дансаар дамжуулан
                          компанитай хуваалцах боломжтой байж магадгүй
                          юм.&nbsp;Хэрэв та бүртгүүлэх явцад эсвэл өөр хэлбэрээр
                          ийм мэдээлэл, хувийн мэдээллийг өгөхөөр шийдсэн бол
                          энэхүү Нууцлалын Бодлоготой нийцүүлэн ашиглах,
                          хуваалцах, хадгалах зөвшөөрлийг компанид олгож байгаа
                          болно.
                        </span>
                      </p>
                      <h3>
                        <span>Технологи ба жигнэмэгийг дагах</span>
                      </h3>
                      <p>
                        <span>
                          Бид күүки болон түүнтэй адилтгах технологийг ашиглан
                          манай үйлчилгээний үйл ажиллагааг хянах, тодорхой
                          мэдээллийг хадгалах боломжтой.&nbsp;Хяналтын
                          технологиуд нь мэдээллийг цуглуулах, хянах, манай
                          үйлчилгээг сайжруулах, дүн шинжилгээ хийхэд ашигладаг
                          гэрэлт цамхаг, шошго, скрипт юм.&nbsp;Бидний ашигладаг
                          технологид дараахь зүйлс орно.
                        </span>
                      </p>
                    </a>
                    <ul>
                      <a>
                        <li>
                          <strong>
                            <span>Күүки эсвэл браузерын күүки.&nbsp;</span>
                          </strong>
                          <span>
                            &nbsp;Күүки бол таны төхөөрөмж дээр байрлуулсан
                            жижиг файл юм.&nbsp;Та бүх хөтөчөөс бүх күүкигээс
                            татгалзах эсвэл күүки хэзээ илгээгдэхийг зааж
                            өгөхийг зааж өгч болно.&nbsp;Гэсэн хэдий ч, хэрэв та
                            күүкийг хүлээн авахгүй бол манай үйлчилгээний зарим
                            хэсгийг ашиглах боломжгүй байж магадгүй
                            юм.&nbsp;Хэрэв та хөтчийнхөө тохиргоог күүкигээс
                            татгалзахаар тохируулаагүй бол манай үйлчилгээ
                            күүкийг ашиглаж болно.
                          </span>
                        </li>
                      </a>
                      <li>
                        <a>
                          <strong>
                            <span>Flash Cookies.&nbsp;</span>
                          </strong>
                          <span>
                            &nbsp;Манай Үйлчилгээний зарим онцлог шинж чанарууд
                            нь таны үйлчилгээ эсвэл таны үйл ажиллагааны
                            талаархи мэдээллийг цуглуулах, хадгалахын тулд орон
                            нутгийн хадгалагдсан объектуудыг (эсвэл Flash
                            Cookies) ашиглаж болно.&nbsp;Flash Cookies-ийг
                            Browser Cookies-т ашигладагтай ижил хөтчийн
                            тохиргоогоор удирддаггүй.&nbsp;Та Flash Cookies-ийг
                            хэрхэн устгах талаар дэлгэрэнгүй мэдээлэл авахыг
                            хүсвэл "Орон нутгийн дундын обьектуудыг
                            идэвхгүйжүүлэх, устгах тохиргоог хаанаас өөрчилж
                            болох вэ?"&nbsp;
                          </span>
                        </a>
                        <a
                          href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                          target="_blank"
                          rel="external nofollow noopener"
                        >
                          https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                        </a>
                        &nbsp;дээрээс авах боломжтой&nbsp;
                      </li>
                      <li>
                        <strong>
                          <span>Вэб гэрэлт цамхагууд.&nbsp;</span>
                        </strong>
                        <span>
                          &nbsp;Манай үйлчилгээний зарим хэсэг, манай имэйлд вэб
                          маяк гэж нэрлэгддэг жижиг электрон файлуудыг агуулж
                          болно (тодорхой gif, пикселийн тэмдэг, нэг пикселийн
                          gif гэх мэт) Компанид зөвшөөрсөн, жишээлбэл, тэдгээр
                          хуудсанд зочилсон хэрэглэгчдийг тоолох. эсвэл имэйл
                          нээгээд бусад холбогдох вэбсайтын статистикийн талаар
                          (жишээлбэл, тодорхой хэсгийн алдар нэрийг бүртгэх,
                          систем, серверийн бүрэн бүтэн байдлыг шалгах).
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span>
                        Күүки нь "Байнгын" эсвэл "Session" күүки байж
                        болно.&nbsp;Байнгын жигнэмэг нь таныг офлайнаар
                        холбогдсон үед таны хувийн компьютер эсвэл хөдөлгөөнт
                        төхөөрөмж дээр хадгалагдах бөгөөд Session Cookies нь
                        таныг вэб хөтчөө хаасны дараа устах
                        болно.&nbsp;Күүкигийн талаар илүү ихийг олж
                        мэдэх:&nbsp;&nbsp;
                      </span>
                      <a
                        href="https://www.freeprivacypolicy.com/blog/cookies/"
                        target="_blank"
                        rel="noopener"
                      >
                        <span>Күүки: Тэд юу хийдэг вэ?&nbsp;</span>
                      </a>
                      <span>.</span>
                    </p>
                    <p>
                      <span>
                        Бид Session болон байнгын жигнэмэгийг доор дурдсан
                        зорилгоор ашигладаг.
                      </span>
                    </p>
                    <ul>
                      <li>
                        <p>
                          <strong>
                            <span>Шаардлагатай / чухал жигнэмэг</span>
                          </strong>
                        </p>
                        <p>
                          <span>Төрөл: Хичээлийн күүки</span>
                        </p>
                        <p>
                          <span>Удирддаг: Бид</span>
                        </p>
                        <p>
                          <span>
                            Зорилго: Эдгээр күүки нь вэбсайтаар дамжуулан танд
                            үйлчилгээ үзүүлэх, түүний зарим функцийг ашиглах
                            боломжийг танд олгоход чухал үүрэг
                            гүйцэтгэдэг.&nbsp;Эдгээр нь хэрэглэгчдийг таних,
                            хэрэглэгчийн дансыг хуурамчаар ашиглахаас урьдчилан
                            сэргийлэхэд тусалдаг.&nbsp;Эдгээр күүкигүй бол таны
                            хүссэн үйлчилгээг үзүүлэх боломжгүй бөгөөд бид
                            эдгээр күүкийг зөвхөн танд эдгээр үйлчилгээг
                            үзүүлэхэд ашигладаг.
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span>
                              Күүкийн бодлого / мэдэгдэл хүлээн авах күүки
                            </span>
                          </strong>
                        </p>
                        <p>
                          <span>Төрөл: Байнгын жигнэмэг</span>
                        </p>
                        <p>
                          <span>Удирддаг: Бид</span>
                        </p>
                        <p>
                          <span>
                            Зорилго: Эдгээр күүки нь хэрэглэгчид вэбсайт дээрх
                            күүкийг ашиглахыг хүлээн зөвшөөрсөн эсэхийг
                            тодорхойлдог.
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span>Функциональ жигнэмэг</span>
                          </strong>
                        </p>
                        <p>
                          <span>Төрөл: Байнгын жигнэмэг</span>
                        </p>
                        <p>
                          <span>Удирддаг: Бид</span>
                        </p>
                        <p>
                          <span>
                            Зорилго: Эдгээр күүки нь вэбсайтаа ашиглахдаа
                            нэвтрэх мэдээлэл, хэлний сонголтоо санах гэх мэт
                            сонголтуудыг санах боломжийг олгодог.&nbsp;Эдгээр
                            күүкигийн зорилго нь танд илүү хувийн туршлага
                            хуримтлуулах, вэбсайтыг ашиглах бүртээ дахин
                            тохиргоо хийхээс зайлсхийх явдал юм.
                          </span>
                        </p>
                      </li>
                    </ul>
                    <p>
                      <span>
                        Бидний ашигладаг күүки болон күүкигийн талаархи таны
                        сонголтын талаар илүү их мэдээлэл авахыг хүсвэл манай
                        күүкийн бодлого эсвэл нууцлалын бодлогын күүки хэсгээс
                        авна уу.
                      </span>
                    </p>
                    <h2>
                      <span>Таны хувийн мэдээллийг ашиглах</span>
                    </h2>
                    <p>
                      <span>
                        Компани нь хувийн мэдээллийг дараахь зорилгоор ашиглаж
                        болно.
                      </span>
                    </p>
                    <ul>
                      <li>
                        <p>
                          <strong>
                            <span>Үйлчилгээгээ хангах, хадгалах</span>
                          </strong>
                          <span>
                            &nbsp;, үүнд манай үйлчилгээний ашиглалтыг хянах.
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span>Дансаа удирдахын тулд:</span>
                          </strong>
                          <span>
                            &nbsp;&nbsp;Үйлчилгээний хэрэглэгчийн бүртгэлийг
                            удирдах.&nbsp;Таны өгсөн хувийн мэдээлэл нь танд
                            бүртгэлтэй хэрэглэгчийн хувьд ашиглах боломжтой
                            үйлчилгээний өөр өөр үйл ажиллагаанд нэвтрэх
                            боломжийг олгоно.
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span>Гэрээ гүйцэтгэхэд зориулан:</span>
                          </strong>
                          <span>
                            &nbsp;&nbsp;Таны худалдаж авсан бүтээгдэхүүн, эд
                            зүйл, үйлчилгээг худалдан авах гэрээг боловсруулах,
                            дагаж мөрдөх, ажиллуулах.
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span>Тантай холбогдохын тулд:</span>
                          </strong>
                          <span>
                            &nbsp;&nbsp;Тантай имэйл, утасны дуудлага, SMS эсвэл
                            бусад ижил төстэй цахим харилцааны хэлбэрүүд,
                            жишээлбэл мобайл програмын шинэчлэлт, аюулгүй
                            байдал, шинэчлэлт зэрэг функциональ байдал,
                            бүтээгдэхүүн, гэрээт үйлчилгээтэй холбоотой
                            мэдээлэл, харилцаа холбооны талаархи мэдэгдэл,
                            шаардлагатай тохиолдолд эсвэл хэрэгжүүлэхэд
                            боломжийн.
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span>Таны</span>
                          </strong>
                          <span>
                            &nbsp;&nbsp;санал болгож буй бусад бараа, үйлчилгээ,
                            үйл явдлын талаархи урьд өмнө худалдаж авсан эсвэл
                            лавласан зүйлтэй ижил төстэй мэдээ, тусгай санал,
                            ерөнхий мэдээллийг танд өгөх.
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span>Таны хүсэлтийг удирдахын тулд:</span>
                          </strong>
                          <span>
                            &nbsp;&nbsp;Бидэнтэй холбоотой таны хүсэлтэд
                            оролцох, удирдах.
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span>Бизнесийн шилжүүлгийн хувьд:</span>
                          </strong>
                          <span>
                            &nbsp;&nbsp;Бид таны мэдээллийг өөрийн хөрөнгийн
                            зарим хэсгийг эсвэл бүхэлд нь үргэлжлүүлэн
                            ажиллуулах эсвэл дампуурах, татан буулгах хэсэг
                            болгон нэгтгэх, нэгтгэх, хуваах, бүтцийн өөрчлөлт,
                            өөрчлөн байгуулах, татан буулгах, бусад хэлбэрээр
                            борлуулах, шилжүүлэх үйл ажиллагааг үнэлэх, явуулах
                            зорилгоор ашиглаж болно. эсвэл манай үйлчилгээний
                            хэрэглэгчдийн талаархи бидний хадгалдаг хувийн
                            мэдээлэл нь шилжүүлсэн хөрөнгийн тоонд багтдаг ижил
                            төстэй ажиллагаа.
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span>Бусад зорилгоор</span>
                          </strong>
                          <span>
                            &nbsp;: Бид таны мэдээллийг өгөгдөлд дүн шинжилгээ
                            хийх, хэрэглээний чиг хандлагыг тодорхойлох,
                            сурталчилгааны кампанит ажлынхаа үр нөлөөг
                            тодорхойлох, үйлчилгээ, бүтээгдэхүүн, үйлчилгээ,
                            маркетинг, туршлагаа үнэлэх, сайжруулах зэрэг бусад
                            зорилгоор ашиглаж болно.
                          </span>
                        </p>
                      </li>
                    </ul>
                    <p>
                      <span>
                        Дараахь тохиолдолд бид таны хувийн мэдээллийг хуваалцаж
                        болно:
                      </span>
                    </p>
                    <ul>
                      <li>
                        <strong>
                          <span>Үйлчилгээ үзүүлэгчидтэй:</span>
                        </strong>
                        <span>
                          &nbsp;&nbsp;Бид тантай холбоо барихын тулд манай
                          үйлчилгээний ашиглалтыг хянах, дүн шинжилгээ хийх
                          зорилгоор бид таны хувийн мэдээллийг үйлчилгээ
                          үзүүлэгчидтэй хуваалцаж болно.
                        </span>
                      </li>
                      <li>
                        <strong>
                          <span>Бизнесийн шилжүүлгийн хувьд:</span>
                        </strong>
                        <span>
                          &nbsp;&nbsp;Бид аливаа нэгдэх, Компанийн хөрөнгийг
                          худалдах, санхүүжүүлэх, манай бизнесийн бүх хэсгийг
                          эсвэл зарим хэсгийг олж авахтай холбоотой эсвэл
                          хэлэлцээрийн явцад таны хувийн мэдээллийг бусад
                          компанид хуваалцаж эсвэл дамжуулж болно.
                        </span>
                      </li>
                      <li>
                        <strong>
                          <span>Түншүүдтэй:</span>
                        </strong>
                        <span>
                          &nbsp;&nbsp;Бид таны мэдээллийг түнш
                          байгууллагуудтайгаа хуваалцаж болох бөгөөд энэ
                          тохиолдолд эдгээр түнш байгууллагуудаас энэхүү
                          Нууцлалын бодлогыг хүндэтгэхийг шаардах
                          болно.&nbsp;Оролцогч компаниудад манай толгой компани
                          болон бусад бүх охин компаниуд, хамтарсан түншлэлүүд
                          эсвэл бидний хяналтанд байдаг эсвэл бидэнтэй нийтлэг
                          хяналтанд байдаг бусад компаниуд орно.
                        </span>
                      </li>
                      <li>
                        <strong>
                          <span>Бизнесийн түншүүдтэй:</span>
                        </strong>
                        <span>
                          &nbsp;&nbsp;Бид танд бизнесийн түншүүдтэйгээ тодорхой
                          бүтээгдэхүүн, үйлчилгээ, урамшуулал санал болгохын
                          тулд таны мэдээллийг хуваалцаж болно.
                        </span>
                      </li>
                      <li>
                        <strong>
                          <span>Бусад хэрэглэгчидтэй:</span>
                        </strong>
                        <span>
                          &nbsp;&nbsp;Та хувийн мэдээллээ бусадтай хуваалцах
                          эсвэл бусадтай бусадтай харьцах үед эдгээр мэдээллийг
                          бүх хэрэглэгчид үзэх боломжтой бөгөөд гадуур олон
                          нийтэд тарааж болно.&nbsp;Хэрэв та бусад
                          хэрэглэгчидтэй харилцах эсвэл Гуравдагч этгээдийн
                          нийгмийн мэдээллийн үйлчилгээгээр дамжуулан
                          бүртгүүлсэн бол таны гуравдагч этгээдийн нийгмийн
                          мэдээллийн үйлчилгээнд холбогдсон харилцагчид таны
                          нэр, танилцуулга, зураг, таны үйл ажиллагааны
                          тодорхойлолтыг харах боломжтой.&nbsp;Үүнтэй адил бусад
                          хэрэглэгчид таны үйл ажиллагааны тодорхойлолтыг үзэх,
                          тантай харилцах, таны профайлыг үзэх боломжтой болно.
                        </span>
                      </li>
                      <li>
                        <strong>
                          <span>Таны зөвшөөрлөөр</span>
                        </strong>
                        <span>
                          &nbsp;: Бид&nbsp;<strong>таны зөвшөөрлөөр</strong>
                          &nbsp;таны хувийн мэдээллийг бусад зорилгоор задруулж
                          болно.
                        </span>
                      </li>
                    </ul>
                    <h2>
                      <span>Таны хувийн мэдээллийг хадгалах</span>
                    </h2>
                    <p>
                      <span>
                        Компани нь таны хувийн мэдээллийг зөвхөн энэхүү
                        Нууцлалын Бодлогод заасан зорилгоор шаардагдах хугацаанд
                        хадгалах болно.&nbsp;Бид таны хувийн өгөгдлийг хуулиар
                        хүлээсэн үүргээ биелүүлэхэд шаардлагатай хэмжээгээр
                        хадгалж, ашиглах болно (жишээлбэл, хэрэв холбогдох
                        өгөгдлийг холбогдох хууль тогтоомжид нийцүүлэн хадгалах
                        шаардлагатай бол), маргааныг шийдвэрлэж, хууль ёсны
                        гэрээ, бодлогоо хэрэгжүүлэх болно.
                      </span>
                    </p>
                    <p>
                      <span>
                        Компани нь дотоод шинжилгээний зорилгоор ашиглалтын
                        өгөгдлийг хадгалах болно.&nbsp;Ашиглалтын өгөгдлийг
                        ерөнхийдөө богино хугацаанд хадгалдаг бөгөөд энэ
                        өгөгдлийг аюулгүй байдлыг бэхжүүлэх эсвэл манай
                        үйлчилгээний ажиллагааг сайжруулахад ашиглахаас бусад
                        тохиолдолд бид энэхүү өгөгдлийг удаан хугацаагаар
                        хадгалах үүрэгтэй.
                      </span>
                    </p>
                    <h2>
                      <span>Таны хувийн мэдээллийг шилжүүлэх</span>
                    </h2>
                    <p>
                      <span>
                        Таны мэдээллийг, түүний дотор хувийн мэдээллийг
                        Компанийн үйл ажиллагааны оффисууд болон
                        боловсруулалтанд оролцогч талууд байрладаг бусад газарт
                        боловсруулдаг.&nbsp;Энэ нь мэдээллийг танай муж, муж,
                        улс эсвэл бусад засгийн газрын харьяалалаас гадуур
                        байрлах компьютерт шилжүүлж, хадгалж болно гэсэн үг юм.
                      </span>
                    </p>
                    <p>
                      <span>
                        Энэхүү Нууцлалын Бодлогыг зөвшөөрч, дараа нь ийм
                        мэдээлэл ирүүлсэн нь энэхүү шилжүүлэгтэй хийсэн
                        тохиролцоог илэрхийлнэ.
                      </span>
                    </p>
                    <p>
                      <span>
                        Компани нь таны өгөгдлийг энэхүү Нууцлалын бодлогын
                        дагуу найдвартай боловсруулж, байгууллага, улс оронд
                        таны хувийн мэдээллийг дамжуулахгүй байхын тулд
                        шаардлагатай бүх арга хэмжээг авах болно. Таны өгөгдөл
                        болон бусад хувийн мэдээлэл.
                      </span>
                    </p>
                    <h2>
                      <span>Таны хувийн мэдээллийг ил тод болгох</span>
                    </h2>
                    <h3>
                      <span>Бизнесийн ажил гүйлгээ</span>
                    </h3>
                    <p>
                      <span>
                        Хэрэв компани нь нэгдэх, нэгдэх эсвэл хөрөнгийн
                        борлуулалттай холбоотой бол таны хувийн мэдээллийг
                        дамжуулж болно.&nbsp;Таны хувийн мэдээллийг шилжүүлэхээс
                        өмнө өөр нууцлалын бодлогод хамрагдахаас өмнө мэдэгдэл
                        өгөх болно.
                      </span>
                    </p>
                    <h3>
                      <span>Хууль сахиулах</span>
                    </h3>
                    <p>
                      <span>
                        Тодорхой нөхцөлд, хуулиар эсвэл төрийн байгууллагуудын
                        (жишээлбэл, шүүх эсвэл засгийн газрын байгууллага) хүчин
                        төгөлдөр хүсэлтэд хариу өгөх шаардлагатай бол Компани
                        таны хувийн мэдээллийг задруулахыг шаардаж магадгүй юм.
                      </span>
                    </p>
                    <h3>
                      <span>Хуулийн бусад шаардлага</span>
                    </h3>
                    <p>
                      <span>
                        Компани нь дараахь арга хэмжээг авах шаардлагатай гэж
                        чин сэтгэлээсээ таны хувийн мэдээллийг ил болгож болно.
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span>Хууль ёсны үүргээ биелүүлэх</span>
                      </li>
                      <li>
                        <span>
                          Компанийн эрх буюу эд хөрөнгийг хамгаалах, хамгаалах
                        </span>
                      </li>
                      <li>
                        <span>
                          Үйлчилгээтэй холбоотой байж болзошгүй алдаанаас
                          урьдчилан сэргийлэх, шалгах
                        </span>
                      </li>
                      <li>
                        <span>
                          Үйлчилгээний хэрэглэгчид эсвэл олон нийтийн хувийн
                          аюулгүй байдлыг хамгаалах
                        </span>
                      </li>
                      <li>
                        <span>Хуулийн хариуцлагаас хамгаалах</span>
                      </li>
                    </ul>
                    <h2>
                      <span>Таны хувийн мэдээллийн аюулгүй байдал</span>
                    </h2>
                    <p>
                      <span>
                        Таны хувийн мэдээллийн аюулгүй байдал нь бидний хувьд
                        чухал боловч интернетээр дамжуулах ямар ч арга, цахим
                        хадгалалтын арга нь 100% аюулгүй байдаг гэдгийг
                        санаарай.&nbsp;Бид таны хувийн мэдээллийг хамгаалахын
                        тулд арилжааны хувьд хүлээн зөвшөөрөгдсөн арга
                        хэрэгслийг ашиглахыг хичээдэг боловч бид түүний бүрэн
                        аюулгүй байдлыг баталгаажуулж чадахгүй.
                      </span>
                    </p>
                    <h1>
                      <span>Хүүхдийн хувийн нууц</span>
                    </h1>
                    <p>
                      <span>
                        Манай үйлчилгээ 13-аас доош насны хэнд ч ханддаггүй. Бид
                        13-аас доош насны хүнээс таньж мэдэх мэдээллийг өөрөө
                        мэдэлгүйгээр цуглуулдаг. бидэнтэй холбоо
                        бариарай.&nbsp;Хэрэв бид эцэг эхийн зөвшөөрлийг
                        баталгаажуулалгүйгээр 13-аас доош насны хэнээс ч хувийн
                        мэдээлэл цуглуулсанаа мэдэж байгаа бол эдгээр мэдээллийг
                        манай серверээс устгах арга хэмжээ авна.
                      </span>
                    </p>
                    <p>
                      <span>
                        Хэрэв бид таны мэдээллийг боловсруулах хууль ёсны
                        үндэслэл болох зөвшөөрөлд найдах шаардлагатай бөгөөд
                        танай улс эцэг эхээс зөвшөөрөл авах шаардлагатай бол бид
                        эдгээр мэдээллийг цуглуулах, ашиглахаасаа өмнө эцэг
                        эхийнхээ зөвшөөрлийг шаардаж болно.
                      </span>
                    </p>
                    <h1>
                      <span>Бусад вэбсайтуудын линкууд</span>
                    </h1>
                    <p>
                      <span>
                        Манай үйлчилгээ нь бидний ашигладаггүй бусад вэбсайтын
                        холбоосыг агуулж болно.&nbsp;Хэрэв та гуравдагч
                        этгээдийн холбоос дээр дарвал тухайн гуравдагч этгээдийн
                        сайт руу чиглүүлэх болно.&nbsp;Очсон сайт бүрийнхээ
                        Нууцлалын Бодлоготой танилцахыг бид танд зөвлөж байна.
                      </span>
                    </p>
                    <p>
                      <span>
                        Бид аливаа хөндлөнгийн сайт эсвэл үйлчилгээний агуулга,
                        нууцлалын бодлого, үйл ажиллагааг хянах эрхгүй бөгөөд
                        хариуцлага хүлээхгүй.
                      </span>
                    </p>
                    <h1>
                      <span>Энэхүү Нууцлалын бодлогын өөрчлөлт</span>
                    </h1>
                    <p>
                      <span>
                        Бид Нууцлалын бодлогоо үе үе шинэчлэх
                        боломжтой.&nbsp;Шинэчлэгдсэн нууцлалын бодлогыг энэ
                        хуудсанд байршуулснаар бид танд өөрчлөлт оруулах тухай
                        мэдэгдэх болно.
                      </span>
                    </p>
                    <p>
                      <span>
                        Өөрчлөлт хүчин төгөлдөр болохоос өмнө бид танд имэйлээр
                        болон / эсвэл манай үйлчилгээнд мэдэгдэх мэдэгдэл өгөх
                        замаар мэдэгдэх бөгөөд энэхүү Нууцлалын бодлогын дээд
                        хэсэгт байрлах "Сүүлийн шинэчлэгдсэн" огноог шинэчлэх
                        болно.
                      </span>
                    </p>
                    <p>
                      <span>
                        Аливаа өөрчлөлтийн талаар энэхүү Нууцлалын бодлогыг үе
                        үе шалгаж байхыг зөвлөж байна.&nbsp;Энэхүү Нууцлалын
                        бодлогын өөрчлөлтийг энэ хуудсанд байрлуулсан тохиолдолд
                        хүчин төгөлдөр болно.
                      </span>
                    </p>
                    <h1>
                      <span>Бидэнтэй холбоо бариарай</span>
                    </h1>
                    <p>
                      <span>
                        Хэрэв танд энэхүү Нууцлалын бодлогын талаар асуулт
                        байвал бидэнтэй холбоо барьж болно.
                      </span>
                    </p>
                    <ul>
                      <li>
                        <p>
                          <span>Имэйлээр: naran.wot@gmail.com</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>Утасны дугаараар: + 976-99886117</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>Facebook хуудас:&nbsp;</span>
                          <a href="https://facebook.com/fasttypemon">
                            <span>https://facebook.com/fasttypemon</span>
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div id="out_en" className="agreement_output">
                    &nbsp;
                  </div>
                </div>
              </section>
              <footer>
                <div className="container">
                  <span>Fasttypemon-ийн нууцлалын бодлого</span>
                </div>
              </footer>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
