import React, { useContext, useState } from "react";
import { Modal, Button, Progress, Row, Col } from "antd";
import Setting from "../setting/Setting";
import FastContext from "../../context/FastContext";
import SettingForm from "../Form/SettingForm";

export default function SettingModal() {
  const ctxfast = useContext(FastContext);
  return (
    <Modal
      style={{ backgroundColor: "yellow", paddingBottom: "0" }}
      bodyStyle={{ backgroundColor: "white", color: "black" }}
      centered
      visible={ctxfast.state.showModalSetting}
      width={600}
      footer={[
        <Button onClick={() => ctxfast.closeSettingModal()}>Хаах</Button>,
      ]}
      onCancel={() => ctxfast.closeSettingModal()}
    >
      <SettingForm />
    </Modal>
  );
}
