import React, { useContext } from "react";
import { Typography, Row, Col, Progress } from "antd";
import FastContext from "../../context/FastContext";
import css from "./style.module.css";
const { Title } = Typography;
export default function MyText() {
  const ctxfast = useContext(FastContext);

  return (
    <div align="center">
      <div className={css.mytextzone}>
        <div style={{ verticalAlign: "middle" }}>
          {ctxfast.state.randomtext != "" ? (
            <table style={{ marginTop: "20px" }}>
              <tr>
                <td
                  style={{
                    width: "400px",
                    backgroundColor: "transparent",
                    verticalAlign: "middle",
                  }}
                  align="right"
                >
                  <div
                    className={css.mytext}
                    style={{
                      paddingRight:
                        ctxfast.state.start.substr(
                          ctxfast.state.start.length - 1,
                          1
                        ) == " "
                          ? "15px"
                          : "0px",
                      color: "green",
                    }}
                  >
                    {ctxfast.state.start.length > 15
                      ? ctxfast.state.start.substr(
                          ctxfast.state.start.length - 15,
                          15
                        )
                      : ctxfast.state.start}
                  </div>
                </td>
                <td
                  align="center"
                  style={{
                    backgroundColor: "green",
                    width: "40px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <span
                    className={css.current}
                    style={{ backgroundColor: "white" }}
                    dangerouslySetInnerHTML={{
                      __html: ctxfast.state.current.replaceAll(" ", "&nbsp;"),
                    }}
                  ></span>
                </td>
                <td
                  className={css.mytext}
                  style={{
                    backgroundColor: "transparent",
                    width: "400px",
                    verticalAlign: "middle",
                  }}
                >
                  <span
                    className={css.mytext}
                    dangerouslySetInnerHTML={{
                      __html:
                        ctxfast.state.end.length > 15
                          ? ctxfast.state.end
                              .substr(0, 15)
                              .replaceAll(" ", "&nbsp;")
                          : ctxfast.state.end.replaceAll(" ", "&nbsp;"),
                    }}
                  ></span>
                </td>
              </tr>
              <tr style={{ height: "10px" }}>
                <td colspan="10"></td>
              </tr>
              <tr>
                <td
                  colspan="10"
                  align="center"
                  style={{
                    color: "#001529",
                    fontWeight: "bold",

                    fontSize: "18px",
                  }}
                >
                 
                </td>
              </tr>
            </table>
          ) : (
            <div>
             
              <div className={css.mysubtitle}>
                10 хуруугаар хурдан бичиж сурах программ
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
