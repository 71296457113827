import React from "react";
import { Steps, Image, Typography } from "antd";
import myhandimage from "../../assets/image/handposition.png";
const { Title, Paragraph } = Typography;
const { Step } = Steps;

export default function UserGuide() {
  return (
    <div style={{ textAlign: "left", width: "100%", alignContent: "left" }}>
      <Title>Заавар, зөвлөмж</Title>
      <Title level={5}>Гараа яаж зөв байрлуулах вэ?</Title>
      <div align="center">
        <Image width={500} src={myhandimage} />
      </div>
      <Paragraph>
        Та F ба J товчлуурууд дээр /жижиг хуванцар товгор мэдрэгдэх болно/
        долоовор хуруунуудаа тавь. Зүүн гарынхаа хуруунуудыг A, S, D, F
        товчлуурууд дээр, баруун гарынхаа хуруунуудыг J, K, L, ; дээр
        байрлуулна. Дээд, доод эгнээний товчлуурыг дарахдаа зөвхөн хувиарласан
        хурууг хөдөлгөх ба дарсаны дараа хуруугаа үндсэн байрлал дээр буцааж
        байрлуулна. Компьютерийн гарлуу харалгүйгээр дэлгэц дээрх заагчийн дагуу
        хуруугаа мэдэрч хөдөлгөнө. Ингэхдээ үсгээ амандаа чанга дуудаж бичвэл үр
        дүнтэй байх болно.
      </Paragraph>
      <Title level={5}>Хэрхэн хурдан, зөв бичих вэ?</Title>
      <Paragraph>
        Алхам 1: Дасгал хэсгийг ажилласнаар бүх үсэг, тэмдэгтээ 10 хуруугаар
        бичиж сурна. Алхам 2: Үг, өгүүлбэр хэсгийг ажилласнаар бататгаж, хурдаа
        сайжруулна. Өмнөх дасгалыг 1 минутанд 150 тэмдэгтээс дээш бичиж сурсаны
        дараа дараагийн дасгалруу шилжинэ. Энэ дүрмээр “дасгал 1”-ээс “өгүүлбэр”
        хэсгийг ажиллаж дууссанаар та ярьж байгааг болон уншиж байгаа текстийг
        шууд компьютерт шивэх чадвартай болно.
      </Paragraph>
      <Title level={5}>Ямар хугацаанд сурах вэ?</Title>
      <Paragraph>
        Өдөрт дунджаар 2-оос дээш цагаар дасгалжуулбал 14 хоногт сурах бүрэн
        боломжтой. Таны сурах чадвар, анхаарал төвлөрөл зэргээс шууд хамаарна.
        Өдөр бүр хуваарь гаргаж тогтмол хийснээр хурдан үр дүнд хүрнэ.
      </Paragraph>
      <p>Танд амжилт хүсье.</p>
    </div>
  );
}
